import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import H2H from './H2H';
import GameStats from './GameStats';
import GameLeaderboard from './GameLeaderboard';
import TeamsGeneralStats from './TeamsGeneralStats';
import GameVisualization from '../GameVisualization';
import GameEventsTimeline from './GameEventsTimeline';
import LiveGameScoreStats from './LiveGameScoreStats';

import {
  LIVE_GAME_DETAILED_STATS_HEADER_ITEMS,
  UPCOMING_GAME_DETAILED_STATS_HEADER_ITEMS,
} from '../../../../Constants/GameStats';

import {AppButton} from '../../../UI';

const GameDetailedStats = ({game}) => {
  const [getParam] = useSearchParams();

  const sportParam = getParam.get('sport');

  const [activeTabs, setActiveTabs] = useState([]);

  return (
    <div
      className="column gap-10 py-md"
      style={{borderBottom: '1px solid var(--appYellow)'}}>
      <div className="row items-center gap-10 justify-center">
        {(game?.is_live
          ? LIVE_GAME_DETAILED_STATS_HEADER_ITEMS.filter(
              item =>
                item?.sports?.includes(sportParam) || !item?.sports?.length,
            )
          : UPCOMING_GAME_DETAILED_STATS_HEADER_ITEMS
        ).map(({id, icon, active_icon, tab}) => (
          <AppButton
            key={id}
            type={19}
            width={80}
            height={30}
            active={activeTabs?.includes(tab)}
            onClick={() => {
              if (activeTabs?.includes(tab)) {
                setActiveTabs([]);
              } else {
                setActiveTabs([tab]);
              }
            }}
            className="row items-center justify-center px-lg py-md"
            title={
              <img
                alt={tab}
                width={20}
                height={20}
                src={activeTabs?.includes(tab) ? active_icon : icon}
              />
            }
          />
        ))}
      </div>
      {activeTabs?.includes('gameVisualization') && <GameVisualization />}
      {activeTabs?.includes('H2H') && <H2H game={game} />}
      {activeTabs?.includes('leaderboard') && <GameLeaderboard game={game} />}
      {activeTabs?.includes('timeline') && <GameEventsTimeline game={game} />}
      {activeTabs?.includes('stats') && <GameStats game={game} />}
      {activeTabs?.includes('liveGameStats') && (
        <LiveGameScoreStats game={game} />
      )}
      {activeTabs?.includes('teamsGeneralStats') && (
        <TeamsGeneralStats
          team1Id={game?.team1_id}
          team2Id={game?.team2_id}
          team1Name={game?.team1_name}
          team2Name={game?.team2_name}
          shirt1Color={game?.info?.shirt1_color}
          shirt2Color={game?.info?.shirt2_color}
        />
      )}
    </div>
  );
};

export default GameDetailedStats;
