import React, {memo, useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';

import './index.scss';

import {setMessagesModal} from '../../Redux/AppSlice';
import {getMessagesModal} from '../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../Hooks';

import {BREAKPOINT_XS} from '../../Constants/Globals';
import {MESSAGES_MODAL_ITEMS} from '../../Constants/Menu';

import {Inbox, NewMessage, Sent} from './Tabs';

import {AppButton, AppModal} from '../UI';

const Messages = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const messagesModal = useSelector(getMessagesModal);

  const [pageIndex, setPageIndex] = useState(0);

  const [newInboxesCount, setNewInboxesCount] = useState(null);

  const onRequestClose = useCallback(() => {
    dispatch(setMessagesModal({isVisible: false}));
  }, [dispatch]);

  const Header = useMemo(
    () => (
      <div className="flex items-center pa-lg">
        <span className="text-subtitle2 blueText bold-600">
          {t('messages')?.toUpperCase?.()}
        </span>
      </div>
    ),
    [t],
  );

  return (
    <div className="flex column fit  messagesContainer">
      <div className="px-md row items-center justify-center">
        <Swiper
          keyboard
          freeMode
          mousewheel
          spaceBetween={8}
          slidesPerView="auto"
          modules={[Keyboard, FreeMode]}
          className={`messagesItemsSwiper full-width`}
          wrapperClass={`${width > BREAKPOINT_XS ? '' : ''}`}>
          {MESSAGES_MODAL_ITEMS?.map(({title, page}) => (
            <SwiperSlide key={title}>
              <AppButton
                small
                type={19}
                height={32}
                width={148}
                fontSize={12}
                titleClassName="bold-600"
                className="flex items-center justify-center"
                title={
                  page === 0 ? (
                    <div className="row gap-5 items-center justify-center py-xxs">
                      <span className="font-12 bold-600">{t(title)}</span>
                      {!!newInboxesCount && (
                        <div
                          className="flex items-center justify-center unReadMessagesCounterContainer"
                          style={{
                            background:
                              page === pageIndex
                                ? 'var(--appBlue)'
                                : 'var(--appYellow)',
                          }}>
                          <span
                            className="font-8 bold-600"
                            style={{
                              color:
                                page === pageIndex
                                  ? '#FD8785'
                                  : 'var(--appBlue)',
                            }}>
                            {newInboxesCount}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    t(title)
                  )
                }
                active={pageIndex === page}
                onClick={() => setPageIndex(page)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {pageIndex === 0 ? (
        <Inbox setNewInboxesCount={setNewInboxesCount} />
      ) : pageIndex === 1 ? (
        <Sent />
      ) : pageIndex === 2 ? (
        <NewMessage setPageIndex={setPageIndex} />
      ) : null}
    </div>
  );
};

export default memo(Messages);
