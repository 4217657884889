export const FIXED_TEAMS = {
  Football: {
    teams: [
      {
        team1Id: 516,
        alias: 'Soccer',
        regionName: 'Spain',
        team1Name: 'Real Madrid',
      },
      {
        team1Id: 497,
        alias: 'Soccer',
        regionName: 'Spain',
        team1Name: 'FC Barcelona',
      },
      {
        team1Id: 457,
        alias: 'Soccer',
        regionName: 'Germany',
        team1Name: 'Bayern Munich',
      },
      {
        team1Id: 394,
        alias: 'Soccer',
        regionName: 'England',
        team1Name: 'Liverpool',
      },
      {
        team1Id: 989,
        alias: 'Soccer',
        regionName: 'Italy',
        team1Name: 'Juventus',
      },
      {
        team1Id: 435,
        alias: 'Soccer',
        regionName: 'France',
        team1Name: 'Paris Saint-Germain',
      },
      {
        team1Id: 391,
        alias: 'Soccer',
        regionName: 'England',
        team1Name: 'Chelsea FC',
      },
      {
        team1Id: 387,
        alias: 'Soccer',
        regionName: 'England',
        team1Name: 'Arsenal FC',
      },
      {
        team1Id: 764,
        alias: 'Soccer',
        regionName: 'Italy',
        team1Name: 'AC Milan',
      },
    ],
  },
  // Volleyball: {
  //   // teams: [],
  // },
  // 'E-Football': {
  //   // teams: [],
  // },
  Basketball: {
    teams: [
      {
        team1Id: 4255,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'Los Angeles Lakers',
      },
      {
        team1Id: 4258,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'Boston Celtics',
      },
      {
        team1Id: 17104,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'Chicago Bulls',
      },
      {
        team1Id: 4254,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'Golden State Warriors',
      },
      {
        team1Id: 4409,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'San Antonio Spurs',
      },
      {
        team1Id: 4261,
        alias: 'Basketball',
        team1Name: 'Miami Heat',
        regionName: 'United States',
      },
      {
        team1Id: 4417,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'Philadelphia 76ers',
      },
      {
        team1Id: 4404,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'Cleveland Cavaliers',
      },
      {
        team1Id: 4253,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'Dallas Mavericks',
      },
      {
        team1Id: 4411,
        alias: 'Basketball',
        regionName: 'United States',
        team1Name: 'Detroit Pistons',
      },
    ],
  },
  // 'Table Tennis': {
  //   // teams: [],
  // },
  Cricket: {
    teams: [
      {
        team1Id: 17562,
        alias: 'Cricket',
        regionName: 'India',
        team1Name: 'India',
      },
      {
        team1Id: 15219,
        alias: 'Cricket',
        team1Name: 'Australia',
        regionName: 'Australia',
      },
      {
        team1Id: 15484,
        alias: 'Cricket',
        team1Name: 'England',
        regionName: 'England',
      },
      {
        team1Id: 18725,
        alias: 'Cricket',
        team1Name: 'South Africa',
        regionName: 'South Africa',
      },
      {
        team1Id: 18906,
        alias: 'Cricket',
        team1Name: 'New Zealand',
        regionName: 'New Zealand',
      },
      {
        team1Id: 15482,
        alias: 'Cricket',
        team1Name: 'Pakistan',
        regionName: 'Pakistan',
      },
      {
        team1Id: 18907,
        alias: 'Cricket',
        team1Name: 'Sri Lanka',
        regionName: 'Sri Lanka',
      },
      {
        team1Id: 176871,
        alias: 'Cricket',
        team1Name: 'West Indies',
        regionName: 'Caribbean',
      },
      {
        team1Id: 20847,
        alias: 'Cricket',
        team1Name: 'Bangladesh',
        regionName: 'Bangladesh',
      },
      {
        team1Id: 15483,
        alias: 'Cricket',
        team1Name: 'Zimbabwe',
        regionName: 'Zimbabwe',
      },
    ],
  },
  Tennis: {
    teams: [
      {
        team1Id: 224473,
        alias: 'Tennis',
        regionName: 'Italy',
        team1Name: 'Jannik Sinner',
      },
      {
        team1Id: 10327,
        alias: 'Tennis',
        regionName: 'Germany',
        team1Name: 'Alexander Zverev',
      },
      {
        team1Id: 402464,
        alias: 'Tennis',
        regionName: 'Spain',
        team1Name: 'Carlos Alcaraz',
      },
      {
        team1Id: 20629,
        alias: 'Tennis',
        regionName: 'USA',
        team1Name: 'Taylor Fritz',
      },
      {
        team1Id: 202714,
        alias: 'Tennis',
        regionName: 'Norway',
        team1Name: 'Casper Ruud',
      },
      {
        team1Id: 145643,
        alias: 'Tennis',
        regionName: 'Russia',
        team1Name: 'Daniil Medvedev',
      },
      {
        team1Id: 127,
        alias: 'Tennis',
        regionName: 'Serbia',
        team1Name: 'Novak Djokovic',
      },
      {
        team1Id: 13745,
        alias: 'Tennis',
        regionName: 'Russia',
        team1Name: 'Andrey Rublev',
      },
      {
        team1Id: 216718,
        alias: 'Tennis',
        regionName: 'Australia',
        team1Name: 'Alex de Minaur',
      },
      {
        team1Id: 20,
        alias: 'Tennis',
        regionName: 'Bulgaria',
        team1Name: 'Grigor Dimitrov',
      },
      {
        team1Id: 180463,
        alias: 'Tennis',
        regionName: 'World',
        team1Name: 'Aryna Sabalenka',
      },
      {
        team1Id: 275675,
        alias: 'Tennis',
        regionName: 'Poland',
        team1Name: 'Iga Swiatek',
      },
      {
        team1Id: 374635,
        alias: 'Tennis',
        regionName: 'USA',
        team1Name: 'Coco Gauff',
      },
      {
        team1Id: 12670,
        alias: 'Tennis',
        regionName: 'Italy',
        team1Name: 'Jasmine Paolini',
      },
      {
        team1Id: 362823,
        alias: 'Tennis',
        regionName: 'China',
        team1Name: 'Qinwen Zheng',
      },
      {
        team1Id: 227670,
        alias: 'Tennis',
        regionName: 'Kazakhstan',
        team1Name: 'Elena Rybakina',
      },
      {
        team1Id: 5440,
        alias: 'Tennis',
        regionName: 'USA',
        team1Name: 'Jessica Pegula',
      },
      {
        team1Id: 225458,
        alias: 'Tennis',
        regionName: 'USA',
        team1Name: 'Emma Navarro',
      },
      {
        team1Id: 19402,
        alias: 'Tennis',
        regionName: 'World',
        team1Name: 'Daria Kasatkina',
      },
      {
        team1Id: 9513,
        alias: 'Tennis',
        regionName: 'Czechia',
        team1Name: 'Barbora Krejcikova',
      },
    ],
  },
  'American Football (NFL)': {
    // American Football (NFL)
    teams: [
      {
        team1Id: 1171,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'New England Patriots',
      },
      {
        team1Id: 1185,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'Pittsburgh Steelers',
      },
      {
        team1Id: 1180,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'Dallas Cowboys',
      },
      {
        team1Id: 1199,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'San Francisco 49ers',
      },
      {
        team1Id: 1196,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'New York Giants',
      },
      {
        team1Id: 1169,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'Denver Broncos',
      },
      {
        team1Id: 739070,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'Washington Commanders',
      },
      {
        team1Id: 1172,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'Las Vegas Raiders',
      },
      {
        team1Id: 1170,
        alias: 'American Football',
        regionName: 'United States',
        team1Name: 'Miami Dolphins',
      },
    ],
  },
  'Baseball (MLB)': {
    teams: [
      {
        team1Id: 5430,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'New York Yankees',
      },
      {
        team1Id: 5417,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'Boston Red Sox',
      },
      {
        team1Id: 5425,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'Los Angeles Dodgers',
      },
      {
        team1Id: 5435,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'St. Louis Cardinals',
      },
      {
        team1Id: 5433,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'San Francisco Giants',
      },
      {
        team1Id: 5467,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'Chicago Cubs',
      },
      {
        team1Id: 5429,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'New York Mets',
      },
      {
        team1Id: 5472,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'Oakland Athletics',
      },
      {
        team1Id: 5416,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'Atlanta Braves',
      },
      {
        team1Id: 5422,
        alias: 'Baseball',
        regionName: 'United States',
        team1Name: 'Detroit Tigers',
      },
    ],
  },
  'Ice Hockey (NHL)': {
    // Ice Hockey (NHL)
    teams: [
      {
        team1Id: 2012,
        alias: 'Ice Hockey',
        regionName: 'Canada',
        team1Name: 'Montreal Canadiens',
      },
      {
        team1Id: 2063,
        alias: 'Ice Hockey',
        regionName: 'Canada',
        team1Name: 'Toronto Maple Leafs',
      },
      {
        team1Id: 2010,
        alias: 'Ice Hockey',
        regionName: 'United States',
        team1Name: 'Detroit Red Wings',
      },
      {
        team1Id: 2015,
        alias: 'Ice Hockey',
        regionName: 'United States',
        team1Name: 'Boston Bruins',
      },
      {
        team1Id: 2017,
        alias: 'Ice Hockey',
        regionName: 'United States',
        team1Name: 'Chicago Blackhawks',
      },
      {
        team1Id: 2058,
        alias: 'Ice Hockey',
        regionName: 'United States',
        team1Name: 'New York Islanders',
      },
      {
        team1Id: 2061,
        alias: 'Ice Hockey',
        regionName: 'United States',
        team1Name: 'Pittsburgh Penguins',
      },
      {
        team1Id: 2019,
        alias: 'Ice Hockey',
        regionName: 'Canada',
        team1Name: 'Edmonton Oilers',
      },
      {
        team1Id: 2406,
        alias: 'Ice Hockey',
        regionName: 'United States',
        team1Name: 'New Jersey Devils',
      },
      {
        team1Id: 2011,
        alias: 'Ice Hockey',
        regionName: 'United States',
        team1Name: 'Los Angeles Kings',
      },
    ],
  },
};
