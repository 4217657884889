import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom/client';
import moment from 'moment';
import './index.scss';

import App from './App';

import store from './Redux/store';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
