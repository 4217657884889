import {
  PRIVACY_POLICY_HTML,
  RESPONSIBLE_GAMBLING_HTML,
  GENERAL_TERMS_AND_CONDITIONS_HTML,
} from './PrivacyPolicy';

export const MAIN_MENU_DROPDOWN_ITEMS = [
  {
    icon: 'myBets',
    action: 'myBets',
    name: 'myBetsLower',
  },
  {
    icon: 'profile',
    name: 'myProfile',
    action: 'myProfile',
  },
  {
    name: 'messages',
    icon: 'messages',
    action: 'messages',
  },
  // {
  //   icon: 'bonuses',
  //   name: 'bonuses',
  // },
  // {
  //   icon: 'copy',
  //   name: 'affiliatesLower',
  // },
  {
    icon: 'logout',
    name: 'logOut',
    action: 'logout',
  },
];

export const BALANCE_DROPDOWN_ITEMS = [
  {
    name: 'deposit',
    action: 'deposit',
  },
  {
    name: 'withdraw',
    action: 'withdraw',
  },
  {
    name: 'transactionHistory',
    action: 'transactionHistory',
  },
  {
    name: 'withdrawStatus',
    action: 'withdrawStatus',
  },
];

export const MAIN_MENU_HEADER_ITEMS = [
  {
    title: 'balanceManagement',
    tabIndex: 0,
  },
  {
    tabIndex: 1,
    title: 'transactionHistory',
  },
  {
    tabIndex: 2,
    title: 'myProfile',
  },
  {
    tabIndex: 3,
    title: 'messages',
  },
];

export const MAIN_MENU_PROFILE_HEADER_ITEMS = [
  {
    page: 0,
    title: 'personalDetails',
  },
  {
    page: 1,
    title: 'changePassword',
  },
  {
    page: 2,
    title: 'selfExclusion',
  },
  {
    page: 3,
    title: 'timeOut',
  },
  {
    page: 4,
    title: 'depositLimits',
  },
  {
    page: 5,
    title: 'verifyAccount',
  },
];

export const MESSAGES_MODAL_ITEMS = [
  {
    page: 0,
    title: 'inbox',
  },
  {
    page: 1,
    title: 'sent',
  },
  {
    page: 2,
    title: 'newMessage',
  },
];

export const BALANCE_MANAGEMENT_TAB_ITEMS = [
  {
    tabIndex: 0,
    title: 'deposit',
  },
  {
    tabIndex: 1,
    title: 'withdraw',
  },
  {
    tabIndex: 2,
    title: 'bonus',
    disabled: true,
  },
  {
    tabIndex: 3,
    title: 'loyaltyProgram',
  },
  {
    tabIndex: 4,
    title: 'sendToFriend',
  },
];

export const PRIVACY_POLICY_HEADER_ITEMS = [
  {
    page: 0,
    title: 'termsConditions',
    html: GENERAL_TERMS_AND_CONDITIONS_HTML,
  },
  {
    page: 1,
    title: 'privacyPolicy',
    html: PRIVACY_POLICY_HTML,
  },
  {
    page: 2,
    title: 'responsibleGaming',
    html: RESPONSIBLE_GAMBLING_HTML,
  },
];

export const MY_BETS_TAB_ITEMS = [
  {
    index: 0,
    title: 'openBets',
  },
  // {
  //   index: 1,
  //   title: 'allBets',
  // },
  {
    index: 2,
    title: 'settledBets',
  },
  // {
  //   index: 3,
  //   title: 'cashedOut',
  // },
  // {
  //   index: 4,
  //   title: 'lost',
  // },
  // {
  //   index: 5,
  //   title: 'won',
  // },
  // {
  //   tabIndex: 6,
  //   title: 'savedBetslip',
  // },
];
