import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useScreenSize} from '../../../../Hooks';

import {
  M_PESA,
  EZY_PESA,
  HALO_PESA,
  TIGO_PESA,
  AIRTEL_NEW,
  PAYMENT_SERVICES,
} from '../../../../Constants/PaymentServices';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';

const OperatorInfoText = ({activeOperator = AIRTEL_NEW}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const operatorName = useMemo(
    () =>
      activeOperator === M_PESA
        ? 'M-Pesa'
        : activeOperator === EZY_PESA
        ? 'Ezy Pesa'
        : activeOperator === TIGO_PESA
        ? 'Tigo Pesa'
        : activeOperator === HALO_PESA
        ? 'Halo Pesa'
        : 'Airtel Money',
    [activeOperator],
  );

  const dialPhoneNumber = useMemo(
    () =>
      activeOperator === M_PESA
        ? '*150*00#'
        : activeOperator === EZY_PESA
        ? '*150*02#'
        : activeOperator === TIGO_PESA
        ? '*150*01#'
        : activeOperator === HALO_PESA
        ? '*150*88#'
        : '*150*60#',
    [activeOperator],
  );

  const chooseOptionText = useMemo(
    () =>
      activeOperator === M_PESA
        ? 'payByMpesa'
        : activeOperator === EZY_PESA
        ? 'payBills'
        : activeOperator === TIGO_PESA
        ? 'payBills'
        : activeOperator === HALO_PESA
        ? 'payBills'
        : 'makePayments',
    [activeOperator],
  );

  return (
    <div
      className={`column whiteText flex-1 operatorInfoContainer justify-end pb-lg ${
        width < BREAKPOINT_XS ? 'text-caption-small' : 'text-caption'
      } `}>
      <div className="row items-center gap-10 py-xxl">
        <div className="line"></div>
        <span
          className="font-13 bold-400"
          style={{color: '#FFFFFF', opacity: 0.5}}>
          {t('orUpper')}
        </span>
        <div className="line"></div>
      </div>
      <div className="whiteText font-13">
        <div className="row items-center gap-10">
          <img
            width={80}
            height={37}
            alt={operatorName}
            className="rounded-borders"
            src={
              PAYMENT_SERVICES?.find(item => item?.title === activeOperator)
                ?.logo
            }
          />
          <span className="bold-600 font-18 whiteText">{operatorName}</span>
        </div>
        <div className="py-md">
          {activeOperator !== AIRTEL_NEW && (
            <span>
              <span>{t('playBillNumber')}</span>:
              <span className="bold-600 yellowText"> 888111</span>
            </span>
          )}
        </div>
      </div>
      <div className="column gap-10">
        <span className="whiteText font-13">
          <span>
            1. <span>{t('onYourPhoneDial')}</span>
            <span className="bold-600 yellowText"> {dialPhoneNumber} </span>
            {activeOperator === AIRTEL_NEW && (
              <span className="bold-600 yellowText">{t('makePayments')}</span>
            )}
          </span>
        </span>
        <span className="whiteText font-13">
          <span>
            2. <span>{t('chooseOption')}</span>
          </span>
          <span className="bold-600 yellowText">
            {' '}
            {activeOperator === AIRTEL_NEW ? 5 : 4}{' '}
            {activeOperator !== AIRTEL_NEW && '('}
            <span>{t(chooseOptionText)}</span>
            {activeOperator !== AIRTEL_NEW && ')'}
          </span>
        </span>
        <span className="whiteText font-13">
          <span>
            3.{' '}
            <span>
              {activeOperator === AIRTEL_NEW
                ? t('chooseOption')
                : t('selectOption')}
            </span>
          </span>
          <span>
            <span className="bold-600 yellowText">
              {' '}
              {activeOperator === HALO_PESA
                ? 3
                : activeOperator === TIGO_PESA
                ? 3
                : activeOperator === AIRTEL_NEW
                ? 6
                : 4}{' '}
            </span>
            <span>
              <span className="bold-600 yellowText">
                {activeOperator === AIRTEL_NEW
                  ? t('gamingAndBetting')
                  : t('enterBusinessNumber')}
              </span>
            </span>
            {activeOperator !== AIRTEL_NEW && (
              <span className="bold-600 yellowText"> (888111)</span>
            )}
          </span>
        </span>
        {activeOperator === AIRTEL_NEW ? (
          <span className="whiteText font-13">
            4. <span>{t('chooseOption')}</span>
            <span className="bold-600 yellowText"> 7 Pigabet</span>
          </span>
        ) : (
          <span className="whiteText font-13">
            4. <span>{t('enter')}</span>{' '}
            <span className="bold-600 yellowText">{t('referenceNumber')}</span>
          </span>
        )}
        {activeOperator === AIRTEL_NEW ? (
          <span className="whiteText font-13">
            5. <span>{t('enter')}</span>{' '}
            <span className="bold-600 yellowText">{t('referenceNumber')}</span>
          </span>
        ) : (
          <span className="whiteText font-13">
            5. <span>{t('enter')}</span>{' '}
            <span className="bold-600 yellowText">{t('amount')}</span>
          </span>
        )}
        {activeOperator === AIRTEL_NEW ? (
          <span className="whiteText font-13">
            6. <span>{t('enter')}</span>{' '}
            <span className="bold-600 yellowText">{t('amount')}</span>{' '}
          </span>
        ) : (
          <span className="whiteText font-13">
            6. <span>{t('enterYour')}</span>{' '}
            <span className="bold-600 yellowText">{t('pinUpper')}</span>{' '}
            {activeOperator === TIGO_PESA && (
              <span className="bold-600 yellowText">{t('ToConfirm')}</span>
            )}
          </span>
        )}
        {activeOperator !== TIGO_PESA && (
          <div className="whiteText font-13">
            <span>
              7. {activeOperator !== AIRTEL_NEW && <span>{t('press')}</span>}
              {activeOperator === AIRTEL_NEW ? (
                <span>
                  <span>{t('enter')}</span>{' '}
                  <span className="bold-600 yellowText">{t('pinUpper')}</span>{' '}
                  <span>{t('ToConfirm')}</span>
                </span>
              ) : (
                <span className="bold-600 yellowText"> {t('toConfirm')}</span>
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(OperatorInfoText);
