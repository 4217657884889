import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import moment from 'moment';

import GameSkeletons from '../../../UI/Skeletons/GameSkeletons';

const H2H = ({game}) => {
  const {t} = useTranslation();

  const shirt1Color = useMemo(
    () =>
      game?.info?.shirt1_color === '000000' &&
      game?.info?.shirt2_color === '000000'
        ? '109121'
        : game?.info?.shirt1_color,
    [game?.info?.shirt1_color, game?.info?.shirt2_color],
  );
  const shirt2Color = useMemo(
    () =>
      game?.info?.shirt2_color === '000000' &&
      game?.info?.shirt2_color === '000000'
        ? 'ffca00'
        : game?.info?.shirt2_color,
    [game?.info?.shirt2_color],
  );

  const liveGameFilteredStats = useMemo(
    () =>
      Object.entries(game?.stats || {}).filter(
        ([key, values]) =>
          !(values.team1_value === null && values.team2_value === null) &&
          !key.startsWith('score_set'),
      ),
    [game?.stats],
  );

  const [upcomingMatchesH2HData, setUpcomingMatchesH2HData] = useState([]);
  const [isUpcomingDataLoading, setIsUpcomingDataLoading] = useState(false);

  useEffect(() => {
    if (!game?.is_live && !!game?.is_stat_available) {
      setIsUpcomingDataLoading(true);
      axios
        .get(
          `https://stats-widget-api.feedconstruct.com/api/en/900/93f428d0-6591-48da-859d-b6c326db2448/Entity/GetTeamCombinatedStat?cId=&stId=&sortByName=&matchId=${game?.id}&ish2h=true&mc=10`,
        )
        .then(response => {
          if (response?.status === 200) {
            setUpcomingMatchesH2HData(response?.data?.H2hMatches?.Matches);
            setIsUpcomingDataLoading(false);
          }
        })
        .catch(() => {
          setIsUpcomingDataLoading(false);
        });
    }
  }, []);

  return !!liveGameFilteredStats?.length && !!game?.is_live ? (
    <div
      style={{maxHeight: 180, background: '#FFFFFF14'}}
      className="pa-md col-xxs-12 row wrap col-gutter-x-xl col-gutter-y-sm scroll scroll-5 rounded-borders">
      {liveGameFilteredStats.map(([key, values]) => {
        const isTeam1Winner = +values.team1_value > +values.team2_value;
        const winnerColor = isTeam1Winner ? shirt1Color : shirt2Color;
        const looserColor =
          +values.team1_value === 0 && +values.team2_value === 0
            ? 'b5b5b5'
            : !isTeam1Winner
            ? shirt1Color
            : shirt2Color;

        const totalValue =
          (+values.team1_value || 0) + (+values.team2_value || 0);
        const winnerPercent =
          totalValue > 0
            ? ((isTeam1Winner ? +values.team1_value : +values.team2_value) /
                totalValue) *
              100
            : +values.team1_value === 0 && +values.team2_value === 0
            ? 0
            : 50;

        return (
          <div
            key={key}
            className={`column items-center col-xxs-12 col-xs-${
              liveGameFilteredStats?.length === 1 ? 12 : 6
            }`}>
            <span className="textCenter whiteText font-12">{t(key)}</span>
            <div className="row items-center gap-5 full-width justify-center">
              <span className="font-10 bold-700 whiteText">
                {+values.team1_value}
              </span>
              <div
                className="full-width overflow-hidden flex"
                style={{
                  height: 4,
                  borderRadius: 4,
                  background: `#${looserColor}`,
                  flexDirection: !isTeam1Winner ? 'row-reverse' : 'row',
                }}>
                <div
                  style={{
                    height: '100%',
                    width: `${winnerPercent}%`,
                    background: `#${winnerColor}`,
                  }}
                />
              </div>
              <span className="font-10 bold-700 whiteText">
                {+values.team2_value}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  ) : isUpcomingDataLoading ? (
    <div className="pb-md">
      <GameSkeletons count={3} />
    </div>
  ) : !!upcomingMatchesH2HData?.length ? (
    <div
      className="column scroll scroll-5 rounded-borders"
      style={{maxHeight: 300}}>
      {upcomingMatchesH2HData?.map((item, index) => (
        <div
          key={item?.Id}
          style={{
            borderBottom:
              index !== upcomingMatchesH2HData?.length - 1
                ? `1px solid #FFFFFF40`
                : '',
            background: '#FFFFFF14',
          }}
          className={`column items-center gap-10 whiteText pa-md`}>
          <div className="row items-center justify-between full-width font-11 lightGrayText bold-600">
            <span>
              {moment?.(item?.ActualStartTime || item?.Date)?.format(
                'DD MMM YYYY, HH:mm',
              )}
            </span>
            <span>{item?.CompetitionName}</span>
          </div>
          <div className="column gap-5 full-width items-center">
            <div className="row items-center gap-10 full-width">
              <div className="row items-center gap-5 justify-end flex-1 overflow-hidden">
                <span className="bold-700 ellipsis">
                  {item?.HomeTeam?.[0]?.name}
                </span>
                <img
                  width="100%"
                  alt={item?.HomeTeam?.[0]?.name}
                  style={{maxHeight: 24, maxWidth: 24}}
                  src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                    item?.HomeTeam?.[0]?.id / 2000,
                  )}/${item?.HomeTeam?.[0]?.id}.png`}
                />
              </div>
              <div className="row items-center py-sm newExtraLightenBlueBackground rounded-borders justify-between">
                <span
                  className="text-center bold-700"
                  style={{width: 40, borderRight: '1px solid #FFFFFF20'}}>
                  {item?.HomeScore}
                </span>
                <span style={{width: 40}} className="text-center bold-700">
                  {item?.AwayScore}
                </span>
              </div>
              <div className="row items-center gap-5 flex-1 justify-start overflow-hidden">
                <img
                  width="100%"
                  alt={item?.AwayTeam?.[0]?.name}
                  style={{maxHeight: 24, maxWidth: 24}}
                  src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                    item?.AwayTeam?.[0]?.id / 2000,
                  )}/${item?.AwayTeam?.[0]?.id}.png`}
                />
                <span className="bold-700 ellipsis">
                  {item?.AwayTeam?.[0]?.name}
                </span>
              </div>
            </div>
            <span className="font-11">{item?.ResultAbbreviation}</span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <span className="whiteText bold-700 fon16 textCenter py-md">
      {t('noDataToShow')}
    </span>
  );
};

export default H2H;
