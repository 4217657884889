import {useEffect, useState} from 'react';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

import TransactionHistoryRowSkeleton from '../../../UI/Skeletons/TransactionHistoryRowSkeleton';

const GameLeaderboard = ({game}) => {
  const {t} = useTranslation();

  const [standingsData, setStandingsData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    if (!!game?.is_stat_available) {
      setIsDataLoading(true);
      axios
        .get(
          `https://stats-widget-api.feedconstruct.com/api/en/900/93f428d0-6591-48da-859d-b6c326db2448/Entity/GetTeamCombinatedStat?cId=&stId=&sortByName=&matchId=${game?.id}&ish2h=true&mc=10`,
        )
        .then(res => {
          if (res?.status === 200) {
            axios
              .get(
                `https://stats-widget-api.feedconstruct.com/api/en/900/93f428d0-6591-48da-859d-b6c326db2448/League/GetLeagueTableByCompetitionIdWDL?allSeason=1&matchCount=5&competitionId=${res?.data?.CompetitionId}&seasonTreeId=${res?.data?.SeasonTreeId}&round=0&allWDL=true`,
              )
              .then(response => {
                setStandingsData(response?.data?.[0]?.Standings);
                setIsDataLoading(false);
              })
              .catch(() => {
                setIsDataLoading(false);
              });
          }
        });
    }
  }, []);

  return isDataLoading ? (
    <TransactionHistoryRowSkeleton />
  ) : standingsData?.length ? (
    <div
      className="pa-md rounded-borders whiteText"
      style={{background: '#FFFFFF14'}}>
      <div className="row items-center justify-between mr-xs">
        <div style={{width: '70%'}} className="row gap-20 bold-600">
          <span style={{width: 30}}>POS</span>
          <span>{t('name')}</span>
        </div>
        <div
          style={{width: '30%'}}
          className="row items-center justify-between text-center bold-600">
          <span style={{width: 35}}>W</span>
          <span style={{width: 35}}>D</span>
          <span style={{width: 35}}>L</span>
        </div>
      </div>
      <div className="column scroll scroll-5" style={{maxHeight: 200}}>
        {standingsData
          .sort((a, b) => +a?.PosAll - +b?.PosAll)
          ?.map(item => {
            const WCount = item?.WDLList?.filter(
              item => item?.Value === 'W',
            )?.length;
            const DCount = item?.WDLList?.filter(
              item => item?.Value === 'D',
            )?.length;
            const LCount = item?.WDLList?.filter(
              item => item?.Value === 'L',
            )?.length;
            return (
              <div
                key={item?.Id}
                className="row items-center justify-between text-center">
                <div style={{width: '70%'}} className="row gap-20 bold-500">
                  <span style={{width: 30}} className="flex-shrink">
                    {item?.PosAll}
                  </span>
                  <span className="ellipsis">{item?.Name}</span>
                </div>
                <div
                  style={{width: '30%'}}
                  className="row items-center justify-between text-center bold-500 grayText">
                  <span style={{width: 35}}>{WCount}</span>
                  <span style={{width: 35}}>{DCount}</span>
                  <span style={{width: 35}}>{LCount}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  ) : (
    <span className="whiteText bold-700 fon16 textCenter py-md">
      {t('noDataToShow')}
    </span>
  );
};

export default GameLeaderboard;
