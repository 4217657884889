import React, {memo, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FreeMode, Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import './index.scss';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {MAIN_MENU_PROFILE_HEADER_ITEMS} from '../../../Constants/Menu';

import {
  TimeOut,
  Verification,
  SelfExclusion,
  DepositLimits,
  ChangePassword,
  PersonalDetails,
} from './Tabs';

import {AppButton} from '../../UI';

import {useScreenSize} from '../../../Hooks';

import {getMainMenuModal} from '../../../Redux/AppSlice/AppSelectors';

const Profile = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const mainMenuModal = useSelector(getMainMenuModal);

  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (!!mainMenuModal?.forceToOpenProfileIndex) {
      setPageIndex(mainMenuModal?.forceToOpenProfileIndex);
    }
  }, [mainMenuModal?.forceToOpenProfileIndex]);

  return (
    <div
      className="flex column full-width profileHeaderWrapper"
      style={{minHeight: '65dvh'}}>
      <div
        className={`flex row gap-5 justify-center px-md pb-md ${
          width > BREAKPOINT_XS ? '' : ''
        }`}>
        <Swiper
          freeMode
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard, FreeMode]}
          className="profileHeaderSwiper full-width">
          {MAIN_MENU_PROFILE_HEADER_ITEMS?.map(({title, page}) => (
            <SwiperSlide key={title}>
              <AppButton
                type={19}
                height={32}
                fontSize={12}
                title={t(title)}
                width="fit-content"
                titleClassName="bold-600"
                active={pageIndex === page}
                onClick={() => setPageIndex(page)}
                className="paddingVertical-8 px-lg flex items-center justify-center"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {pageIndex === 0 ? (
        <PersonalDetails />
      ) : pageIndex === 1 ? (
        <ChangePassword />
      ) : pageIndex === 2 ? (
        <SelfExclusion />
      ) : pageIndex === 3 ? (
        <TimeOut />
      ) : pageIndex === 4 ? (
        <DepositLimits />
      ) : pageIndex === 5 ? (
        <Verification />
      ) : null}
    </div>
  );
};

export default memo(Profile);
