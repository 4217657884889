import React, {memo, useMemo} from 'react';

import './index.scss';

const SportItem = ({title, setActiveSport, activeSport}) => {
  const isSelected = useMemo(() => activeSport === title, [activeSport, title]);

  return (
    <div
      onClick={() => setActiveSport(title)}
      className={`filterItem ${
        isSelected && 'activeFilterButton'
      } cursor-pointer`}>
      <span className={`filterTitle ${isSelected && 'activeFilterTitle'}`}>
        {title}
      </span>
    </div>
  );
};

export default memo(SportItem);
