import React, {memo, useCallback, useTransition} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setMainMenuModal} from '../../Redux/AppSlice';
import {getMainMenuModal} from '../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../Hooks';

import Header from './Header';
import Profile from './Profile';
import BalanceManagement from './BalanceManagement';
import TransactionHistory from './TransactionHistory';
import './index.scss';
import {BREAKPOINT_XS} from '../../Constants/Globals';

import {AppButton, AppModal, AppPrivacyPolicy} from '../UI';
import {useTranslation} from 'react-i18next';
import {Autoplay, FreeMode, Keyboard} from 'swiper/modules';
import {MAIN_MENU_HEADER_ITEMS} from '../../Constants/Menu';
import {Swiper, SwiperSlide} from 'swiper/react';
import Messages from '../Messages';

const MainMenu = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const mainMenuModal = useSelector(getMainMenuModal);

  const onMainMenuRequestClose = useCallback(() => {
    dispatch(setMainMenuModal({isVisible: false, isDesktop: false}));
  }, [dispatch]);

  return (
    <AppModal
      closeIconType={2}
      header={
        // mainMenuModal?.tabIndex !== 0 ? (
        //   <span className="bold-600 font-25 text-uppercase pa-md">
        //     {t('mainMenu')}
        //   </span>
        // ) : (
        //   // <Header />
        //   <div className="flex column items-center full-width">
        //     <span className="py-md font-20 blueText bold-700">
        //       {t('balanceManagement')?.toUpperCase()}
        //     </span>
        //   </div>
        // )
        <span
          className="bold-600 font-15 text-uppercase d px-lg flex items-center"
          style={{height: 50}}>
          {t('myAccount')}
        </span>
      }
      border
      isOpen={mainMenuModal?.isVisible}
      contentClassName="darkBlueBackground"
      onRequestClose={onMainMenuRequestClose}
      removeContentPaddings
      maxWidth={width < BREAKPOINT_XS ? '95vw' : undefined}>
      <div className="column  full-width gap-10 mainMenuContainer">
        <div
          style={{borderBottom: '1px solid #FFFFFF14'}}
          className="row items-center gap-10 justify-center py-md full-width px-md">
          <Swiper
            spaceBetween={5}
            slidesPerView="auto"
            wrapperClass={`${width > BREAKPOINT_XS ? '' : ''}`}
            className={`mainMenuHeaderTabsSwiper full-width `}>
            {MAIN_MENU_HEADER_ITEMS?.map(({title, tabIndex}) => (
              <SwiperSlide key={title}>
                <AppButton
                  type={19}
                  height={32}
                  fontSize={12}
                  title={t(title)}
                  width="fit-content"
                  titleClassName="bold-600"
                  active={mainMenuModal?.tabIndex === tabIndex}
                  onClick={() => dispatch(setMainMenuModal({tabIndex}))}
                  className="rounded-borders px-lg paddingVertical-7 items-center flex justify-center"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {mainMenuModal?.tabIndex === 0 ? (
          <BalanceManagement />
        ) : mainMenuModal?.tabIndex === 1 ? (
          <TransactionHistory />
        ) : mainMenuModal?.tabIndex === 2 ? (
          <Profile />
        ) : mainMenuModal?.tabIndex === 3 ? (
          <Messages />
        ) : null}
      </div>
    </AppModal>
  );
};

export default memo(MainMenu);
