import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const GameSkeletons = ({count = 7}) => (
  <Skeleton
    height={80}
    width="100%"
    count={count}
    duration={0.7}
    className="top-5"
    borderRadius="5px"
    baseColor="var(--lightBlue)"
    highlightColor="var(--appBlue)"
  />
);

export default memo(GameSkeletons);
