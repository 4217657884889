import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import './TeamsGeneralStats.scss';

import {getLanguage} from '../../../../Redux/AppSlice/AppSelectors';

import GameStatsSkeleton from '../../../UI/Skeletons/GameStatsSkeleton';

import {ReactComponent as ShirtIcon} from '../../../../Assets/Icons/Globals/shirt.svg';

const TeamsGeneralStats = ({
  team1Id,
  team2Id,
  team1Name,
  team2Name,
  shirt1Color,
  shirt2Color,
}) => {
  const {t} = useTranslation();
  const [getParam] = useSearchParams();

  const language = useSelector(getLanguage);

  const [stats, setStats] = useState([]);
  const [isStatsLoading, setIsStatsLoading] = useState(false);

  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const getItemColor = useCallback(
    result =>
      result === 'W'
        ? 'var(--lightGreen)'
        : result === 'L'
        ? 'var(--appRed)'
        : 'var(--appYellow)',
    [],
  );

  const sortedGameInfo = useMemo(() => {
    const team1Stats = stats?.find?.(item => item?.EntityId === team1Id);
    const team2Stats = stats?.find?.(item => item?.EntityId === team2Id);
    return [
      ...(team1Stats?.EntityId ? [team1Stats] : []),
      ...(team1Stats?.EntityId ? [team2Stats] : []),
    ];
  }, [stats, team1Id, team2Id]);

  useEffect(() => {
    setIsStatsLoading(true);
    fetch(
      `https://krosstats.betcoapps.com/api/${language}/900/93f428d0-6591-48da-859d-b6c326db2448/Entity/GetGeneralStatsInfo?matchId=${sportGameIdParam}`,
    )
      .then(response => response.json())
      .then(statsData => setStats(statsData?.GeneralStatsInfoResult))
      .catch(err => console.log(err))
      .finally(() => setIsStatsLoading(false));
  }, [language, sportGameIdParam]);

  return isStatsLoading ? (
    <div className="mb-md">
      <GameStatsSkeleton />
    </div>
  ) : (
    <div
      style={{background: '#FFFFFF14'}}
      className="teamsGeneralStatsContainer rounded-borders pa-md relative justify-center items-center">
      <div
        className={`flex column ${
          !sortedGameInfo?.length ? 'gap-10' : 'gap-10'
        } justify-center full-height`}>
        <div className="row justify-between items-center gap-5">
          <div className="column overflow-hidden">
            <div className="row items-center gap-5">
              <ShirtIcon
                width={15}
                height={15}
                className="flex-shrink"
                fill={`#${shirt1Color}`}
              />
              <span className="whiteText bold-600 font-15 ellipsis">
                {team1Name}
              </span>
            </div>
            {!!sortedGameInfo?.[0]?.Position &&
              !!sortedGameInfo?.[0]?.Points && (
                <div className="grayText bold-500 text-caption">
                  <span>{t('position')}: </span>
                  <span>{sortedGameInfo?.[0]?.Position} </span>
                  <span>{t('points')}: </span>
                  <span>{sortedGameInfo?.[0]?.Points}</span>
                </div>
              )}
          </div>
          <div className="flex blackBackground rounded-borders">
            {sortedGameInfo?.[0]?.GeneralInfoWDL?.map((item, index) => (
              <div
                key={index}
                style={{
                  borderRight:
                    index !== sortedGameInfo?.[0]?.GeneralInfoWDL?.length - 1
                      ? '1px solid gray'
                      : '',
                }}
                className="flex py-xs rowCenter statItem">
                <span
                  style={{color: getItemColor(item)}}
                  className="bold-700 font-12">
                  {item}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="row justify-between items-center gap-5">
          <div className="column overflow-hidden">
            <div className="row items-center gap-5">
              <ShirtIcon
                width={15}
                height={15}
                className="flex-shrink"
                fill={`#${shirt2Color}`}
              />
              <span className="whiteText bold-600 font-15 ellipsis">
                {team2Name}
              </span>
            </div>
            {!!sortedGameInfo?.[1]?.Position &&
              !!sortedGameInfo?.[1]?.Points && (
                <div className="grayText bold-500 text-caption">
                  <span>{t('position')}: </span>
                  <span>{sortedGameInfo?.[1]?.Position} </span>
                  <span>{t('points')}: </span>
                  <span>{sortedGameInfo?.[1]?.Points}</span>
                </div>
              )}
          </div>
          <div className="flex blackBackground rounded-borders">
            {sortedGameInfo?.[1]?.GeneralInfoWDL?.map((item, index) => (
              <div
                key={index}
                className="flex py-xs rowCenter statItem"
                style={{
                  borderRight:
                    index !== sortedGameInfo?.[1]?.GeneralInfoWDL?.length - 1
                      ? '1px solid gray'
                      : '',
                }}>
                <span
                  style={{color: getItemColor(item)}}
                  className="bold-700 font-12">
                  {item}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TeamsGeneralStats);
