import React, {memo, useCallback} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useSearchParams} from 'react-router-dom';
import {FreeMode, Keyboard} from 'swiper/modules';

import {AppButton} from '../../UI';

import {getNextSevenDaysUnixTimestamps} from '../../../Utils/GetNextSevenDaysFilters';

import './index.scss';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {useScreenSize} from '../../../Hooks';

const LiveCalendarFilters = () => {
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const getDayParam = getParam.get('day');
  const getRegionParam = getParam.get('region');

  const onClickItem = useCallback(
    item => {
      // const dayParamParts = getDayParam?.split('_');
      //
      // if (!!getRegionParam) {
      //   // getParam.delete('region'); //TODO TESTING - we commented this line to fix some bug, if its breaking something just uncomment
      // }
      // if (
      //   !!getDayParam &&
      //   +dayParamParts?.[0] === item?.startOfDay &&
      //   +dayParamParts?.[1] === item?.endOfDay
      // ) {
      //   getParam.delete('day');
      // } else {
      //   getParam.set('day', `${item?.startOfDay}_${item?.endOfDay}`);
      // }
      // setGetParam(getParam);

      const clickedItem = `${item.startOfDay}_${item.endOfDay}`;

      let items = getDayParam?.split?.('-') || []; // Split string by "-"

      if (items.includes(clickedItem)) {
        items = items.filter(item => item !== clickedItem);
      } else {
        items.push(clickedItem);
      }

      if (items?.length > 0) {
        getParam.set('day', items.join('-'));
      } else {
        getParam.delete('day');
      }

      setGetParam(getParam);
    },
    [getDayParam, getParam, setGetParam],
  );

  return (
    <div
      className={`liveCalendarFilters pr-sm ${
        width > BREAKPOINT_XS ? 'pl-xl flex justify-start align-center' : ''
      }`}>
      <Swiper
        freeMode
        keyboard
        mousewheel
        spaceBetween={5}
        slidesPerView="auto"
        modules={[Keyboard, FreeMode]}
        wrapperClass={width > BREAKPOINT_XS ? ' mx-auto pa-xxs' : 'pa-xxs '}
        className={`liveCalendarFiltersSwiper wrapperBorders ${
          width > BREAKPOINT_XS ? 'desktop full-width' : 'mobile mt-sm '
        }`}>
        {(getNextSevenDaysUnixTimestamps(true) || [])?.map((item, index) => (
          <SwiperSlide key={index}>
            <AppButton
              type={19}
              height={30}
              active={
                getDayParam
                  ?.split('-')
                  ?.includes(`${item.startOfDay}_${item.endOfDay}`)
                // +item?.startOfDay === +getDayParam?.split?.('_')?.[0] &&
                // +item?.endOfDay === +getDayParam?.split?.('_')?.[1]
              }
              className="row items-center justify-center matchItem"
              onClick={() => onClickItem(item)}
              title={
                <span className={`font-12 bold-600`}>
                  {`${item?.dayName} ${item?.day}`}
                </span>
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(LiveCalendarFilters);
