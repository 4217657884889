import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {useSocket} from '../../../../Hooks';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {getUser} from '../../../../Redux/UserSlice/UserSelectors';
import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../Redux/AppSlice';

import {
  SITE_ID,
  GET_LOYALTY_INFO,
  BONUS_EXCHANGE_POINTS,
} from '../../../../Constants/Socket';
import {APP_MODAL_CLOSE_TIMEOUT_MS} from '../../../../Constants/Globals';

import {ReactComponent as LPIcon} from '../../../../Assets/Icons/Globals/LP.svg';
import {ReactComponent as CurrencyIcon} from '../../../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as LPWhiteIcon} from '../../../../Assets/Icons/Globals/LPWhite.svg';
import {ReactComponent as ConvertIcon} from '../../../../Assets/Icons/Globals/convert.svg';

import {AppButton, AppInput} from '../../../UI';

const LoyaltyProgram = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {getLoyaltyInfo, bonusExchangePoints} = useSocket();

  const user = useSelector(getUser);
  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);

  const [levelsData, setLevelsData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [loyaltyInfoData, setLoyaltyInfoData] = useState({});
  const [isExchangeLoading, setIsExchangeLoading] = useState(false);
  const [loyaltyPointsAmount, setLoyaltyPointsAmount] = useState('');

  const currentLevel = useMemo(
    () =>
      loyaltyInfoData?.LoyaltyLevels?.find(
        item => item?.Id === loyaltyInfoData?.LoyaltyLevelId,
      ) || loyaltyInfoData?.LoyaltyLevels?.[0],
    [loyaltyInfoData?.LoyaltyLevelId, loyaltyInfoData?.LoyaltyLevels],
  );

  const convertHandler = useCallback(() => {
    setIsExchangeLoading(true);
    bonusExchangePoints({points: loyaltyPointsAmount});
  }, [bonusExchangePoints, loyaltyPointsAmount]);

  const onChangeText = useCallback(
    text => {
      if (errorMessage) {
        setErrorMessage(null);
      }
      setLoyaltyPointsAmount(text);
    },
    [errorMessage],
  );

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case GET_LOYALTY_INFO:
          if (data?.data?.result === 0) {
            setLoyaltyInfoData(data?.data?.details);
          }
          break;
        case BONUS_EXCHANGE_POINTS:
          if (data?.data?.code === 0 && !!data?.data?.data?.Amount) {
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('loyaltyPointsExchangeCompletedTitle', {
                    currency,
                    amount: data?.data?.data?.Amount,
                  }),
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          } else {
            if (data?.data?.data) {
              setErrorMessage(data?.data?.data);
            }
          }
          setIsExchangeLoading(false);
          break;
        default:
          break;
      }
    },
    [currency, dispatch, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    setIsDataLoading(true);
    getLoyaltyInfo();
    axios
      .get(
        `https://go.cms.hogoxiyfctcdpjbu.com/api/public/v1/eng/partners/${SITE_ID}/loyalty-points`,
      )
      .then(response => setLevelsData(response?.data?.data));
  }, []);

  useEffect(() => {
    if (!!levelsData?.length && !!loyaltyInfoData?.LoyaltyLevelId) {
      setIsDataLoading(false);
    }
  }, [levelsData?.length, loyaltyInfoData?.LoyaltyLevelId]);

  return isDataLoading ? (
    <div className="blueBackground pa-lg rounded-borders mt-lg full-height">
      <Skeleton
        width="100%"
        height={200}
        duration={0.7}
        borderRadius="5px"
        baseColor="var(--lightBlue)"
        highlightColor="var(--appBlue)"
      />
    </div>
  ) : (
    <div className="blueBackground pa-lg rounded-borders mt-lg gap-20 column full-height">
      <div className="row items-center gap-10">
        <img
          alt="myLevel"
          style={{width: 45}}
          src={`https://cms.hogoxiyfctcdpjbu.com/${
            levelsData?.find(item => item?.id === currentLevel?.Id)?.src
          }`}
        />
        <div className="column whiteText">
          <span className="font-12 bold-600">{currentLevel?.Name}</span>
          <span className="font-10">{t('myLevel')}</span>
        </div>
      </div>
      <div className="row items-center gap-20 justify-between">
        <div className="row items-center gap-10">
          <span className="whiteText bold-500 font-12">{t('myPoints')}</span>
          <div className="whiteBackground rounded-borders gap-6 row items-center px-lg py-sm">
            <LPIcon width={17} height={15} />
            <span className="font-12 bold-600 blueText">
              {bigNumbersPrettier(user?.loyalty_point)}
            </span>
          </div>
        </div>
        <div className="row items-center gap-10">
          <LPWhiteIcon width={21} height={18} />
          <span className="whiteText bold-500 font-15">1</span>
          <ConvertIcon width={15} height={9} />
          <CurrencyIcon width={16} height={9} />
          <span className="whiteText bold-500 font-15">1</span>
        </div>
      </div>
      <div className="column gap-10">
        <AppInput
          type={10}
          inputType="number"
          onChange={onChangeText}
          className="whiteBackground"
          placeholder={t('enterAmount')}
          leftIcon={
            <div
              style={{
                height: 45,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
              }}
              className="row items-center justify-center whiteBackground px-md">
              <LPIcon width={17} height={15} />
            </div>
          }
        />
        <AppButton
          type={3}
          onClick={convertHandler}
          title={t('convert')}
          loading={isExchangeLoading}
          disabled={!loyaltyPointsAmount?.trim() || !!errorMessage}
        />
        {!!errorMessage && (
          <span className="redText font-11">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};

export default memo(LoyaltyProgram);
