import React, {useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useTranslation} from 'react-i18next';
import './GameEventsTimeline.scss';

import {ReactComponent as ShirtIcon} from '../../../../Assets/Icons/Globals/shirt.svg';

const GameEventsTimeline = ({game}) => {
  const {t} = useTranslation();

  const [isExpanded, setIsExpanded] = useState(true);

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  return (
    <div className="column gap-10 full-width gameEventsTimelineContainer">
      <div className="full-width paddingHorizontal-7 lineupsContainer rounded-borders items-center justify-center flex">
        <div className="full-width row timeLineSeparators justify-between items-center relative">
          <div
            className="currentTimeProgress"
            style={{
              width: `${
                (game?.info?.current_game_state === 'Half Time'
                  ? 0.5
                  : +game?.info?.current_game_time / game?.match_length) * 100
              }%`,
            }}
          />

          {Array.from({length: 19}, (_, i) => (
            <div
              key={i}
              className={`line ${
                i % 2 === 0 ? 'full-height' : ''
              } column items-center justify-center`}>
              {i === 0 && (
                <div className="flex justify-center items-center periodMarker">
                  <span className="font-8">0</span>
                </div>
              )}
              {i === 9 && (
                <div className="flex justify-center items-center periodMarker halfTime">
                  <span className="font-7">HT</span>
                </div>
              )}
              {i === 18 && (
                <div className="flex justify-center items-center periodMarker">
                  <span className="font-8">{game?.match_length}</span>
                </div>
              )}
            </div>
          ))}
          {game?.live_events
            ?.sort((a, b) => b?.time - a?.time)
            ?.map((item, index) => {
              const eventType =
                +item?.type_id === 2
                  ? 'redCard'
                  : +item?.type_id === 3
                  ? 'yellowCard'
                  : +item?.type_id === 4
                  ? 'corner'
                  : +item?.type_id === 1
                  ? 'goal'
                  : +item?.type_id === 6
                  ? 'substitution'
                  : +item?.type_id === 5
                  ? 'penalty'
                  : '';
              const currentLeft =
                (+item?.current_minute?.replace(`'`, '') / game?.match_length) *
                100;

              const isOverlapping = game?.live_events?.some(
                (prevItem, prevIndex) =>
                  prevIndex < index &&
                  Math.abs(
                    (+prevItem?.current_minute?.replace(`'`, '') /
                      game?.match_length) *
                      100 -
                      currentLeft,
                  ) < 3,
              );

              const adjustedTopBottom = isOverlapping
                ? +item?.side === 1
                  ? {top: -12}
                  : {bottom: -12}
                : +item?.side === 1
                ? {top: -22}
                : {bottom: -22};

              return (
                <div
                  style={{
                    position: 'absolute',
                    ...adjustedTopBottom,
                    left: `${currentLeft}%`,
                  }}
                  key={`${item?.time_utc}_${item?.time}_${index}`}>
                  {!!eventType && (
                    <img
                      width={10}
                      alt={eventType}
                      src={require(
                        `../../../../Assets/Icons/Globals/${eventType}.svg`,
                      )}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {game?.live_events?.length && (
        <div>
          <div
            {...getToggleProps({
              onClick: () => setIsExpanded(prev => !prev),
            })}>
            <div
              style={{background: '#ffffff14'}}
              className={`pa-sm row items-center ${
                isExpanded ? 'rounded-top-borders' : 'rounded-borders'
              }`}>
              <span className="font-12 whiteText bold-500">
                {t('gameEvents')}
              </span>
            </div>
          </div>
          <div {...getCollapseProps()}>
            <div
              style={{background: '#ffffff14', maxHeight: 160}}
              className={`scroll-5 scroll column mt-xxs rounded-bottom-borders`}>
              {game?.live_events
                ?.sort((a, b) => b?.time - a?.time)
                ?.map((event, index) => {
                  const eventType =
                    +event?.type_id === 2
                      ? 'redCard'
                      : +event?.type_id === 3
                      ? 'yellowCard'
                      : +event?.type_id === 4
                      ? 'corner'
                      : +event?.type_id === 1
                      ? 'goal'
                      : +event?.type_id === 6
                      ? 'substitution'
                      : +event?.type_id === 5
                      ? 'penalty'
                      : '';
                  const shirtColor =
                    game?.info?.shirt1_color === '000000' &&
                    game?.info?.shirt2_color === '000000'
                      ? +event?.side === 1
                        ? game?.info?.shirt1_color
                        : 'FFFFFF'
                      : +event?.side === 1
                      ? game?.info?.shirt1_color
                      : game?.info?.shirt2_color;
                  return (
                    <div
                      className="pa-sm row items-center gap-5"
                      key={`${event?.time_utc}_${event?.time}_${index}`}
                      style={{borderBottom: '1px solid var(--appBlue)'}}>
                      <ShirtIcon
                        width={13}
                        height={13}
                        fill={`#${shirtColor}`}
                      />
                      <div
                        style={{
                          width: 1,
                          height: 14,
                        }}
                        className="row items-center justify-center blueBackground"
                      />
                      {!!eventType && (
                        <img
                          width={10}
                          alt={eventType}
                          src={require(
                            `../../../../Assets/Icons/Globals/${eventType}.svg`,
                          )}
                        />
                      )}
                      <span className="whiteText font-11 bold-500">
                        {t(eventType)} {event?.current_minute}{' '}
                        {+event?.side === 1
                          ? game?.team1_name
                          : game?.team2_name}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameEventsTimeline;
