// import {
//   CASINO_SCREEN,
//   POKER_SCREEN,
//   SPORTS_SCREEN,
//   JACKPOT_SCREEN,
//   BET_SLIP_SCREEN,
//   LIVE_CHAT_SCREEN,
//   LAST_PLAYED_SCREEN,
//   TOP_WINNERS_SCREEN,
//   CASINO_HOME_SCREEN,
//   LIVE_CASINO_SCREEN,
// } from '../Navigation/ScreenNames';
//
import {
  TOP_WINNERS_HEADER_TITLE,
  LAST_WINNERS_HEADER_TITLE,
} from '../Constants/Globals';
import {
  FILTERS_LIVE_NAME,
  FILTERS_TODAY_NAME,
  HEADER_FILTERS_POKER,
  HEADER_FILTERS_SLOTS,
  HEADER_FILTERS_SPORTS,
  FILTERS_LIVE_CALENDAR,
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_MULTIPLE_DAY_NAME,
  HEADER_FILTERS_LIVE_CASINO,
  FILTERS_POPULAR_MATCHES_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  HEADER_FILTERS_13_MATCH_JACKPOT,
  FILTERS_POPULAR_COMPETITIONS_NAME,
  FILTERS_FAVOURITE_COMPETITIONS_NAME,
  FILTERS_OUTRIGHT_NAME,
} from '../Constants/MatchFilters';

export const eng = {
  // [CASINO_SCREEN]: 'Casino',
  // [SPORTS_SCREEN]: 'Sports',
  [FILTERS_LIVE_NAME]: 'Live',
  [FILTERS_FAVOURITE_COMPETITIONS_NAME]: 'Favorite',
  // [CASINO_HOME_SCREEN]: 'Home',
  // [BET_SLIP_SCREEN]: 'BetSlip',
  liveChat: 'Live Chat',
  // [LIVE_CASINO_SCREEN]: 'Live Casino',
  // [LAST_PLAYED_SCREEN]: 'Last Played',
  // [POKER_SCREEN]: 'Poker',
  // [TOP_WINNERS_SCREEN]: 'Top Winners',
  [FILTERS_LIVE_CALENDAR]: 'Live Calendar',
  [FILTERS_BOOSTED_ODDS_NAME]: 'Boosted',
  [FILTERS_OUTRIGHT_NAME]: 'Outright',
  [FILTERS_POPULAR_MATCHES_NAME]: 'Popular',
  [FILTERS_UPCOMING_MATCHES_NAME]: 'Upcoming',
  [FILTERS_MULTIPLE_DAY_NAME]: 'Multiple of the day',
  [FILTERS_TODAY_NAME]: "Today's Bets",
  [FILTERS_POPULAR_COMPETITIONS_NAME]: 'Popular Competitions',
  [TOP_WINNERS_HEADER_TITLE]: 'TOP WINNERS',
  [LAST_WINNERS_HEADER_TITLE]: 'LAST WINNERS',
  searchTeam: 'Search team',
  searchGame: 'Search game',
  jackpot: 'Jackpot',
  explore: 'Explore',
  search: 'Search',
  apps: 'Apps',
  home: 'HOME',
  lobby: 'Lobby',
  skill: 'Skill',
  draw: 'DRAW',
  drawLower: 'Draw',
  prematch: 'Prematch',
  english: 'English',
  match: 'Match',
  swahili: 'Swahili',
  chinese: 'Chinese',
  drawLowerCase: 'Draw',
  away: 'AWAY',
  reset: 'RESET',
  Set: 'Set',
  league: 'League',
  promos: 'PROMOS',
  signIn: 'SIGN IN',
  register: 'REGISTER',
  registerLower: 'Register',
  chooseLanguage: 'Choose Language',
  ok: 'OK',
  seeMore: 'SEE MORE',
  seeMoreOptions: 'SEE MORE OPTIONS',
  forgotPassword: 'Forgot password?',
  forgetPassword: 'Forget password',
  changeLanguage: 'Change Language',
  continue: 'CONTINUE',
  depositNow: 'DEPOSIT NOW',
  logIn: 'LOGIN',
  alreadyHaveAnAccount: 'Already have an account?',
  signInWaiting: "SIGN IN, WE'RE WAITING FOR YOU.",
  signInLower: 'Sign in',
  rememberMe: 'Remember Me',
  newMember: 'New member?',
  affiliates: 'AFFILIATES',
  passwordPlaceholder: 'Password',
  registerAnAccount: 'REGISTER AN ACCOUNT',
  phoneCode: 'Code:',
  jackpotPool: 'JACKPOT POOL',
  phoneNumberPlaceholder: 'Phone number',
  firstName: 'First name',
  lastName: 'Last name',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  affiliateCode: 'Affiliate code (optional)',
  agreement: 'AGREEMENT (TICK TO AGREE)',
  agreementText: 'I confirm that I am 18 years and older. I agree to PigaBet',
  iConfirm: 'I confirm that I am 18 years and older.',
  iAgree: 'I agree to PigaBet ',
  andThe: ' and the ',
  privacyPolicy: 'Privacy Policy',
  termsConditions: 'General Terms & Conditions',
  congratulations: 'Congratulations!',
  youAreRegistered: 'Your are now registered.',
  pleaseDeposit: 'Please deposit to enjoy our services!',
  resetPassword: 'Reset Here',
  ResetPassword: 'Reset Password',
  resetPasswordTitle: 'Reset Password',
  enterYourPhoneNumber: 'Enter your phone number to reset password: ',
  resetPhoneNumberPlaceholder: 'Phone number starting with 255',
  smsHasBeenSent: 'An sms has been sent to the number provided',
  resetCodePlaceholder: 'Enter received code to reset',
  resend: 'RESEND',
  send: 'SEND',
  sendOtp: 'Send OTP',
  leagues: 'Leagues',
  clearAll: 'CLEAR ALL',
  clearSearch: 'Clear Search',
  resentSearches: 'Recent Searches:',
  applyChanges: 'APPLY CHANGES',
  cancel: 'CANCEL',
  noGames: 'NO GAMES',
  anSmsHasBeenSent: 'An sms has been sent to the number provided',
  confirmNewPassword: 'Confirm your new password:',
  enterYourOTP: 'Enter your OTP',
  done: 'DONE',
  passwordWasResetSuccessfully: 'Password was Reset Successfully!',
  passwordWasChangedSuccessfully: 'Password was changed successfully!',
  generalTerms: 'General Terms & Conditions',
  responsibleGambling: 'Responsible Gambling',
  help: 'Help',
  statistics: 'Statistics',
  licences: 'Licences',
  myBets: 'MY BETS',
  phoneErrorMsg: 'Must be exactly 9 digits',
  phoneErrorMsgWithCode: 'Must be exactly 12 digits',
  passwordErrorMsg: 'Must be at least 4 characters',
  nameErrorMsg: 'Only letters(no symbols and/or digits)',
  chooseDateOfBirth: 'Choose date of birth',
  mustStartWithCode: 'Phone must start with 255',
  clientNotFound: 'Client not found',
  invalidPhoneNumberFormatError: 'Invalid phone number format',
  logout: 'LOGOUT',
  date: 'Date',
  dateAndId: 'Date & ID',
  mainMenu: 'Main Menu',
  mainMenuUpper: 'MAIN MENU',
  mainBalance: 'Main Balance',
  TZS: 'TZS',
  withdrawableMoney: 'Withdrawable Money:',
  totalBonusMoney: 'Total Bonus Money:',
  contactUs: 'Contact Us',
  loyaltyPoints: 'Loyalty Points',
  loyaltyPointsWithValue: 'Loyalty Points: {0}',
  legalAndCompliance: 'Legal & Compliance',
  settings: 'Settings',
  messages: 'Messages',
  promotions: 'Promotions',
  myBetsMainMenu: 'My Bets',
  howToVideos: 'How-To Videos',
  balanceManagement: 'Balance Management',
  viewLoyaltyPoints: 'View Loyalty Points',
  Language: 'Language',
  myProfile: 'My Profile',
  personalDetails: 'Personal Details',
  changePassword: 'Change Password',
  selfExclusion: 'Self-Exclusion',
  timeOut: 'Time-Out',
  timeOutReminder: 'Time Out Reminder',
  depositLimits: 'Deposit Limits',
  verifyAccount: 'Verify Account',
  accountVerification: 'Account Verification',
  depositLimitsSet: 'Deposit Limits Set',
  saveChanges: 'SAVE CHANGES',
  save: 'SAVE',
  YES: 'YES',
  yes: 'Yes',
  no: 'No',
  NO: 'NO',
  OK: 'OK',
  confirmPasswordText: 'Your password has been successfully changed',
  DONE: 'DONE',
  currentPassword: 'Current Password',
  newPassword: 'New password',
  changePasswordError: 'New password and confirm password do not match',
  week: 'Week',
  depositLimitText: 'Deposit Limit has been successfully changed',
  ID: 'ID',
  Mobile255xxx: 'Mobile 255xxx',
  email: 'Email',
  passportID: 'Passport/ID',
  birthdate: 'Birth date',
  gender: 'Gender',
  country: 'Country',
  city: 'City',
  eligibleForBonus: 'Eligible for bonus:',
  selfExclusionText:
    'We are committed to promoting responsible gambling. If you need to take a break, our self-exclusion feature lets\n' +
    'you temporarily suspend your account. During this time, you will not be able to access your account or place any bets.',
  CONFIRMATION: 'CONFIRMATION',
  selfExclusionTextModal: 'Do you confirm that you wish to self-exclude?',
  selfExclusionPeriodSet: 'Self Exclusion Period Set',
  sorryToSeeYouGoDeeYouIn: 'Sorry to see you go. see you in',
  timeOutText:
    'Taking breaks is important for maintaining a healthy gaming balance. If you feel like you need a pause,\n' +
    'consider setting up a Time Out. You can choose the duration that suits you best.',
  timeOutModalText: 'Do you confirm that you wish to take a time-out?',
  timeOutPeriodSet: 'Time-Out Period Set',
  depositLimitsText:
    'To help you manage your gaming responsibly, we offer the option to set deposit limits on your account. You can choose\n' +
    'daily, weekly, or monthly limits to stay within your budget and ensure a safe and enjoyable experience.',
  months: 'months',
  timeout: 'Timeout',
  years: 'years',
  yearLower: 'year',
  hours: 'hours',
  days: 'days',
  oneWeek: 'One week',
  '6months': '6 months',
  '1yearLower': '1 years',
  '2years': '2 years',
  '5years': '5 years',
  '24hours': '24 hours',
  '3days': '3 days',
  male: 'Male',
  female: 'Female',
  chooseGender: 'Choose Gender',
  chooseCountry: 'Choose Country',
  myBetsTitle: 'My Bets',
  betSlip: 'BETSLIP',
  betSlipLower: 'BetSlip',
  openBets: 'OPEN BETS',
  settledBets: 'SETTLED BETS',
  remove: 'Remove',
  savedBetSlips: 'SAVED BETSLIPS',
  multiple: 'Multiple',
  system: 'System',
  single: 'Single',
  chain: 'Chain',
  multipleUpper: 'MULTIPLE',
  systemUpper: 'SYSTEM',
  singleUpper: 'SINGLE',
  chainUpper: 'CHAIN',
  removeAll: 'Remove All',
  odds: 'Odds',
  possibleWin: 'Possible win',
  tax: 'Tax',
  allBets: 'All Bets',
  finished: 'Finished',
  finalPayout: 'Final payout',
  finalPayoutUpper: 'FINAL PAYOUT',
  acceptOddChanges: 'Accept Odd Changes',
  bonusInfo: 'Bonus info',
  saveBetSlip: 'SAVE BETSLIP',
  placeBet: 'PLACE BET',
  max: 'MAX',
  needHelp: 'Need Help? Live Chat Support - 24/7',
  enterStake: 'Enter stake',
  emptyBetSlip: 'Your BetSlip is empty',
  loggedOutWarning: 'To place your bet please, ',
  placeBetWarning: 'To place your bet please, enter stake',
  or: ' or ',
  betPlacedCongrats: 'Congrats! Bet was successfully placed',
  addOneMore:
    'Add one more to get {{percent}}% Win Booster ({{currency}} {{amount}})',
  addOneMoreFor: 'Add 1 more for',
  winBooster: 'Win Booster',
  placeAnotherBet: 'Place another bet?',
  cashOutSuccess: 'Cash out completed successfully!',
  hasBeenAddedToYourWallet: ' has been added to your wallet.',
  noBetsToShow: 'No Bets to show',
  liveUpper: 'LIVE',
  show: 'Show',
  cashOut: 'CASHOUT{0}',
  cashout: 'CASHOUT',
  confirmCashOut: 'CONFIRM CASHOUT',
  totalOdds: 'Total Odds',
  stake: 'Stake',
  totalBonus: 'Total bonus',
  totalPigabetReturn: 'Total Pigabet Return:',
  bonus: 'Bonus',
  loyaltyProgram: 'Loyalty program',
  sendToFriend: 'Send to friend',
  selectCasinoToWithdrawFrom: 'Please select a casino to withdraw from',
  amountPlaceholder: 'Enter your bet amount',
  viewSelections: 'VIEW SELECTIONS:',
  hideSelections: 'HIDE SELECTIONS:',
  betNow: 'BET NOW',
  acceptChanges: 'ACCEPT CHANGES AND PLACE BET',
  signInOrRegister: 'To Place your bet, Sign in or Register',
  toPlaceYourBet: 'To Place your bet',
  accumulatorBonus: 'Win Booster',
  winner: 'WINNER',
  handicap: 'HANDICAP',
  totals: 'TOTALS',
  doubleChance: 'DOUBLE CHANCE',
  bothTeamToScore: 'BOTH TEAM TO SCORE',
  emptyBoosted: 'No boosted odds available at the moment',
  emptyMultiple: 'No multiple of the day bets currently available',
  won: 'Won',
  lost: 'Lost',
  wonAmount: 'Won amount',
  cashedOut: 'Cashed Out',
  cashedOutWithAmount: 'CASHED OUT - {{amount}}',
  returnedWithAmount: 'RETURNED - {{amount}}',
  today: 'Today',
  todayUpper: 'TODAY',
  tomorrow: 'Tomorrow',
  tomorrowUpper: 'TOMORROW',
  yesterday: 'Yesterday',
  thisWeek: 'This Week',
  lastWeek: 'Last Week',
  thisMonth: 'This Month',
  lastMonth: 'Last Month',
  wonUpper: 'WON - {{amount}}',
  wonStateUpper: 'WON',
  lostUpper: 'LOST',
  cashedOutUpper: 'CASHED OUT',
  back: 'BACK',
  position: 'Position:',
  points: 'Points',
  prize: 'Prize',
  emptyEvents: 'No events are available at the moment',
  emptyFavouriteMarkets:
    'You have no favorite markets. To add favorites you need to tap the star icons next to the markets in the list.',
  all: 'All',
  confirmLower: 'Confirm',
  cancelLower: 'Cancel',
  resetLower: 'Reset',
  notResultedUpper: 'NOT RESULTED',
  returnedUpper: 'RETURNED',
  returned: 'Returned',
  deposit: 'Deposit',
  depositUpper: 'DEPOSIT',
  withdraw: 'Withdraw',
  withdrawUpper: 'WITHDRAW',
  transactionHistory: 'Transaction History',
  withdrawStatus: 'Withdraw Status',
  currentBalance: 'Current Balance',
  enterAmountToDeposit: 'Enter amount to deposit',
  enterAmountToSend: 'Enter amount to send',
  pleaseSelectAPaymentMethod: 'Please select a payment method:',
  enterAmountForExchange:
    'Enter the amount of points you want to exchange and press Submit',
  pointsYouWantToExchangePlaceholder: 'Points you want to exchange...',
  exchangeShopUpper: 'EXCHANGE SHOP',
  exchangeShop: 'Exchange Shop',
  submitUpper: 'SUBMIT',
  purchaseOrderCompletedTitle: 'Purchase Order Completed!',
  purchaseOrderCompletedDescription:
    "We'll contact you for more info. Thank you.",
  withdrawCompletedTitle:
    '{{currency}} {{amountValue}} successfully withdrawn!',
  depositCompletedTitle: 'You will receive a one time password.',
  depositCompletedDescription: 'Enter your PIN to proceed.',
  loyaltyPointsExchangeCompletedTitle:
    '{{amount}} {{currency}} successfully exchanged!',
  selectWithdrawMethod: 'Select Withdrawal Method:',
  amount: 'Amount',
  enterAmountToWithdraw: 'Enter amount to withdraw',
  minMaxLimits:
    'Minimum {{currency}} {{minValue}}, Maximum {{currency}} {{maxValue}}',
  toAllowSend: 'You and the receiver must be verified',
  totalStake: 'Total stake',
  showMore: 'Show more',
  orUpper: 'Or',
  showLess: 'Show less',
  emptyMessages: 'You do not have any messages',
  emptyTransactions: 'You do not have any transactions',
  clearUpper: 'CLEAR',
  callToActionText: '{0} {1} x {2} = FINAL PAYOUT: {0} {3}',
  count: 'Count',
  minOdds: 'Min odds',
  minStake: 'Min stake',
  playBillNumber: 'Paybill Number',
  onYourPhoneDial: 'On your phone, dial',
  chooseOption: 'Choose option',
  selectOption: 'Select option',
  step: 'Step',
  enterBusinessNumber: 'Enter business Number',
  businessNumber: 'Business number',
  amountYouWantToDeposit: 'Amount you want to deposit',
  enterYour: 'Enter your',
  enterAmount: 'Enter Amount',
  pinUpper: 'PIN',
  press: 'Press',
  toConfirm: '"1" to confirm',
  ToConfirm: 'to confirm',
  depositMustBeMadeWith:
    'Deposit must be made with a phone number registered with Pigabet',
  okWithCommas: '"OK"',
  gamingAndBetting: 'Gaming and Betting',
  payBills: 'Pay Bills',
  payByMpesa: 'Pay by M-pesa',
  enterMerchantNumber: 'Enter Merchant Number',
  makePayments: 'Make Payments',
  enterThe: 'Enter the',
  enterAny: 'Enter any',
  referenceNumber: 'reference number (Phone number)',
  enter: 'Enter',
  minimum: 'Minimum',
  maximum: 'Maximum',
  mobileNumber: 'Mobile Number:',
  yourOperatorIsNotSupported:
    'Your operator is not recognized, please choose another method',
  reversed: 'Reversed',
  paid: 'Paid',
  pending: 'Pending',
  rejected: 'Rejected',
  time: 'Time',
  id: 'ID',
  method: 'Method',
  status: 'Status',
  noDataToShow: 'No data to show',
  noDataAvailable: 'No data available at the moment',
  cannotBeCombined: "Highlighted events can't be combined",
  thereAreDeletedEvents: 'There are deleted events',
  filter: 'Filter',
  transactionType: 'Transaction Type',
  category: 'Category',
  finalBalance: 'Final Balance',
  applyUpper: 'APPLY',
  type: 'Type',
  main: 'Main',
  casino: 'Casino',
  seeWithdrawStatus: 'SEE WITHDRAW STATUS',
  promoCode: 'Promo Code',
  bonuses: 'Bonuses',
  bonusesHistory: 'Bonus History',
  canceled: 'Canceled',
  new: 'New',
  call: 'Call',
  whatsApp: 'WhatsApp',
  instagram: 'Instagram',
  insufficientBalance: 'Insufficient balance',
  facebook: 'Facebook',
  twitter: 'Twitter',
  youtube: 'Youtube',
  confirmFingerprint: 'Confirm fingerprint',
  epl: 'EPL',
  nbcPremier: 'NBC Premier League',
  laLiga: 'La Liga',
  ligue1: 'Ligue 1',
  serieA: 'Serie A',
  bundesliga: 'Bundesliga',
  minimumStakeError:
    'Minimum total stake requirements not met (1 {{currency}})',
  maximumStakeError: 'Maximum total stake is ({{maxBet}} {{currency}})',
  betSelectionChanged: 'There are changes in odds',
  liveCasino: 'Live Casino',
  slots: 'Slots',
  poker: 'Poker',
  skillGames: 'Skill Games',
  blast: 'Blast',
  crash: 'Crash',
  aviator: 'Aviator',
  keno: 'Keno',
  mines: 'Mines',
  bigHilo: 'Hi low',
  BoostedRTP: 'Boosted RTP',
  dice: 'Dice',
  noCasinoGames: 'No Games',
  gameInfo: 'Game Info',
  playNow: 'PLAY NOW',
  demo: 'Demo',
  signInToAbleContinue: 'To be able to continue, you need to Sign In,',
  jackpotModalTitle: 'PigaBet Casino Jackpot',
  totalWinner: 'Total Winner',
  largestWinner: 'Largest Winner',
  lastWinner: 'Last Winner',
  other: 'Other',
  noVideos: 'No Videos',
  notStarted: 'Starting soon',
  tryLater: 'Please Try Later',
  eventHasBeenDeleted: 'Event has been deleted',
  eventHasBeenBlocked: 'Event has been blocked',
  noInternet: 'Oops, No Internet Connection',
  makeSureWifiIsOn:
    'Make sure wifi or cellular data is turned on and then try again.',
  verificationCode: 'VerifyAccount code',
  smsVerification: 'SMS VerifyAccount',
  getCode: 'GET CODE',
  smsConfirmation: 'SMS CONFIRMATION',
  theCodeWillBeSent: "The code will be sent to {{phone}} user's phone number",
  codeWasSent: 'The confirmation code was sent successfully',
  PendingWithdrawalRequests: 'You already have an active withdrawal request',
  biometricSensorAlertTitle:
    'Would you like to use "Face ID/Fingerprint" to Login',
  biometricSensorAlertDescription:
    'With "Face ID/Fingerprint" you can easy and secure access to your Pigabet account',
  biometricSensorAlertCancelText: 'Set up later',
  biometricSensorAlertConfirmText: 'Use "Face ID/Fingerprint"',
  copy: 'Copy',
  copied: 'Copied',
  playerId: 'Player ID: ',
  playerID: 'Player ID',
  OVER: 'OVER',
  UNDER: 'UNDER',
  lockedOdds: 'Odds Locked',
  pigabetCasinoJackpot: 'PIGABET CASINO JACKPOT',
  suspended: 'Suspended',
  startingIn: 'Starts in',
  S: 'Seconds',
  M: 'Minutes',
  sec: 'sec.',
  min: 'min',
  outright: 'Outright',
  mainAffiliates: 'MAIN AFFILIATES',
  about: 'About',
  responsibleGaming: 'Responsible Gaming',
  faqs: "FAQ's",
  sportsBetting: 'Sport Betting',
  results: 'Results',
  enterCurrentPassword: 'Enter current password',
  enterNewPassword: 'Enter new password',
  matchCenter: 'Match Center',
  liveCalendar: 'Live Calendar',
  applications: 'Applications',
  generalLink: 'General Link',
  fullScreen: 'Full Screen',
  downloadIosPigabetApp: 'Download IOS Pigabet App',
  downloadAndroidPigabetApp: 'Download Android Pigabet App',
  copyRight: '© {{year}} Pigabet | All Rights Reserved.',
  enterVerificationCode: 'Enter verification code',
  contact: 'Contact',
  footerText:
    'Pigabet is owned by Dunia Investment Limited, Internet sports betting licence registration No. SBI0000000Z1, Internet casino\n' +
    'licence registration No. OCL000000003 Our Site is Only Open to Persons Over the Age of 18. Be Gamble Aware. Gamble Responsibly.',
  ourSiteIsOnlyOpen:
    'Our Site is Only Open to Persons Over the Age of 18. Be Gamble Aware. Gamble Responsibly.',
  sports: 'Sports',
  searchSports: 'Search sports...',
  searchCasinoGame: 'Search for game...',
  [HEADER_FILTERS_POKER]: 'POKER',
  [HEADER_FILTERS_SLOTS]: 'SLOTS',
  [HEADER_FILTERS_SPORTS]: 'SPORTS',
  [HEADER_FILTERS_LIVE_CASINO]: 'LIVE CASINO',
  [HEADER_FILTERS_13_MATCH_JACKPOT]: 'JACKPOT',
  myBetsLower: 'My Bets',
  affiliatesLower: 'Affiliates',
  logOut: 'Log out',
  internalMessages: 'Internal Messages',
  pushNotifications: 'Push Notifications',
  phoneCall: 'Phone Call',
  sms: 'SMS',
  notifyMeAbout: 'Notify me about news and offers by:',
  preferences: 'Preferences',
  passportNumber: 'Passport Number',
  address: 'Address',
  birthDate: 'Birth date',
  mobile: 'Mobile',
  enterEmailAddress: 'Enter email address',
  enterYourCity: 'Enter your city',
  enterYourCountry: 'Enter your country',
  enterPassportNumber: 'Enter passport number',
  enterHomeAddress: 'Enter home address',
  enterYourPassword: 'Enter your password',
  passwordsMismatch: "Passwords don't match",
  enterDay: 'Enter day',
  enterWeek: 'Enter week',
  enterMonth: 'Enter month',
  hideBalance: 'Hide balance',
  inbox: 'Inbox',
  sent: 'Sent',
  newMessage: 'New Message',
  enterMessageSubjectHere: 'Enter message subject here',
  enterMessageTextHere: 'Enter message text here',
  subject: 'Subject',
  message: 'Message',
  somethingWentWrong:
    'Something went wrong. Please try again later or contact support',
  checkInUpcomingMatches: 'Check in upcoming matches',
  winAmount: 'Win Amount',
  game: 'Game',
  tooLongMessage: 'Message is too long',
  maximumSubjectCharsAllowed: 'Maximum 255 characters allowed',
  allProviders: 'ALL PROVIDERS',
  allProvidersLower: 'All Providers',
  providers: 'Providers',
  Providers: 'PROVIDERS',
  lastSlotsPlayed: 'Last Slots Played',
  lastLiveCasinoPlayed: 'Last Live Casino Played',
  lastBoostedRTPPlayed: 'Last BoostedRTP Played',
  lastSkillPlayed: 'Last Skill Games Played',
  lastPlayed: 'Last Played',
  betId: 'Bet ID: ',
  matchResult: 'Match Result',
  total: 'Total',
  doubleChanceFilter: 'Double Chance',
  bothTeamToScoreTitle: 'Both Teams To Score',
  handicapTitle: 'Handicap',
  asianHandicap: 'Asian Handicap',
  oddEven: 'EVEN/ODD',
  signInForPlay: 'To Play The Game Please Sign in or Register',
  groupByCompetition: 'Group by competition',
  featuredGames: 'Featured Games',
  verified: 'Verified',
  unVerified: 'Unverified',
  totalBonusBalance: 'Total Bonus Balance',
  copyBetUpper: 'COPY BET',
  betCopiedUpper: 'BET COPIED',
  bettingCodePlaceholder: 'Betting code...',
  enterYourBettingCode: 'Enter your betting code',
  load: 'Load',
  enterFriendPhoneNumber: "Enter your friend's phone number",
  ClientNotVerified: 'Your friend is not verified',
  verifyNow: 'Verify now',
  verifyAccountNote:
    'To complete your registration, please verify your account by uploading clear, high-quality photos of the necessary\n' +
    'documents, such as a government-issued ID and proof of address, through your account settings. Ensure that all\n' +
    'details are visible and that the name on your documents matches the name on your account.',
  verifyAccountNote2:
    'Verifying your account also grants you access to exclusive promotions and offers.\n' +
    'If you need assistance, our support team is here to help.',
  verifyAccountNote3: 'Thank you for your cooperation!',
  openGallery: 'Open Gallery',
  openCamera: 'Open Camera',
  name: 'Name',
  yourFileWasSuccessfullyUploaded: 'File was successfully uploaded',
  yourSubmissionIsUnderReview: 'Your submission is under review',
  sendToFriendSuccess: '{{amount}} {{currency}} sent successfully',
  viewMore: 'View More',
  shareYourBet: 'Share your Bet',
  sharedBet: "{{user}}'s BETSLIP",
  sportsBet: 'Sports Bet',
  addToMyBetSlip: 'ADD TO MY BETSLIP',
  placedOn: 'Placed On',
  shareYourBetNow: 'Share your Bet Now',
  competitions: 'Competitions',
  bettingShops: 'Betting shops',
  hide: 'Hide',
  ticket: 'Ticket',
  topLeagues: 'Top leagues',
  searchAnyLeague: 'Search Any League',
  searchAnyGame: 'Search Any Game',
  searchProvider: 'Search Provider',
  valuedPartners: 'Valued Partners',
  paymentMethods: 'Payment Methods',
  followUsOn: 'Follow us on',
  playResponsibly: 'Play Responsibly',
  aboutUs: 'About Us',
  blog: 'Blog',
  news: 'News',
  companyRegulated:
    'Dunia investment Limited is proudly regulated by the Gaming board of Tanzania since 2011',
  duniaInvestment: 'Dunia Investment Limited (Trading as Pigabet)',
  aboutUsText:
    "Since 2011, Pigabet has been Tanzania's leading online betting platform, operated by Dunia Investment Limited. We offer extensive sports betting markets, live betting, and casino games with a user-friendly interface. Licensed by the Gaming Board of Tanzania, we prioritise security, fairness, and responsible gaming. Our mission is to provide an unparalleled betting experience with exceptional customer service. Join the Pigabet Family now.\n",
  TIN: 'TIN: ',
  companyRegNumber: 'Company Registration Number: ',
  offices: 'Offices: ',
  sportsBettingLicense: 'Sports Betting License: ',
  internetCasinoLicense: 'Internet Casino License: ',
  freeBet: 'Freebet',
  freeBetCondition: 'Freebet is a new offer for making free bets.',
  exp: 'Exp.',
  importantInfo: 'Important Information:',
  selfExclusionInfo1: '· Self-exclusion is irreversible for the chosen period.',
  selfExclusionInfo2:
    '· You will not receive marketing materials during this time.',
  forMoreSupport: 'For more support, visit ',
  orCall:
    ' or call 0808 802 0133. Thank you for choosing to gamble responsibly.',
  chooseFile: 'Choose File',
  range: 'Range',
  topWinners: 'Top Winners',
  lastWinners: 'Last Winners',
  searchMarketUpper: 'SEARCH MARKET',
  half: 'Half',
  quarter: 'Quarter',
  myAccount: 'My account',
  slotProviders: 'SLOT PROVIDERS',
  liveCasinoProviders: 'CASINO PROVIDERS',
  lastPlayedProviders: 'LAST PLAYED PROVIDERS',
  yourPick: 'Your Pick:',
  winner3: 'Match Winner',
  totalSet: 'Set Total',
  totalHalf: 'Half Total',
  totalPeriod: 'Period Total',
  resultsFound: 'Results Found',
  toSeeFavouriteGames: 'To See Favourite Games Please Sign in or Register',
  betAmount: 'Bet Amount',
  shareBet: 'Share Bet',
  invalidBookingId: 'Invalid booking id',
  shareBetTitle:
    'I can win {{amount}} TZS at www.pigabet.co.tz join now and let the games begin! \nClick here to see my bet: ',
  popularLeagues: 'Popular Leagues',
  countries: 'Countries',
  selectedLeagues: 'Selected leagues',
  betBuilder: 'Bet Builder',
  betSuccess: 'Bet Success',
  attack: 'Attack',
  dangerous_attack: 'Dangerous attack',
  goal_kick: 'Goal kick',
  throw_in: 'Throw-in',
  substitution: 'Substitution',
  ballSafe: 'Ball safe',
  free_kick: 'Free kick',
  corner: 'Corner',
  kick_off: 'Kick-off',
  offside: 'Offside',
  yellow_card: 'Yellow card',
  passes: 'Passes',
  shot_blocked: 'Shot Blocked',
  shot_on_target: 'Shot On Target',
  goalkeeper_save: 'Goalkeeper Save',
  shot_off_target: 'Shot Off Target',
  goal: 'Goal',
  point: 'Point',
  delivery: 'Delivery',
  over: 'Over',
  wicet: 'Wicket',
  period: 'Period',
  twoPoints: 'Two Points',
  threePoints: 'Three Points',
  serviceFault: 'Service Fault',
  double_fault: 'Double Faults',
  aces: 'Aces',
  free_Throw: 'Free Throws',
  handball_Timeout: 'Timeouts',
  sevenMeter_Throw: '7-Meter Throws',
  penaltyMissed: 'Penalties Missed',
  ball_possession: 'Ball Possession',
  foul: 'Fouls',
  destroy_tower: 'Towers Destroyed',
  destroy_inhibitor: 'Inhibitors Destroyed',
  destroy_nexus: 'Nexus Destroyed',
  gameEvents: 'Game events',
  redCard: 'Red Card',
  yellowCard: 'Yellow Card',
  penalty: 'Penalty',
  myPoints: 'My Points',
  myLevel: 'My Level',
  convert: 'Convert',
  searchAnyLeagueOrTeam: 'Search Any League or Team',
  yourLastSearch: 'Your last search',
  topTeams: 'Top Teams',
};
