import React, {useMemo} from 'react';

import {FIXED_GAME_STATS} from '../../../../Constants/GameStats';

const STATS_TO_MERGE = [
  // change or add new stats here (keep ordering)
  'score_set1',
  'yellow_card',
  'red_card',
  'corner',
  'throw_in',
  'penalty',
  'offside',
  'substitution',
  'foul',
];

const GameStats = ({game}) => {
  const mergedGameStats = useMemo(
    () =>
      STATS_TO_MERGE.reduce((mergedStats, stat) => {
        if (game.stats?.[stat]) {
          mergedStats[stat] = {
            team1_value:
              game.stats?.[stat].team1_value ??
              FIXED_GAME_STATS[stat].team1_value,
            team2_value:
              game.stats?.[stat].team2_value ??
              FIXED_GAME_STATS[stat].team2_value,
          };
        } else if (!!FIXED_GAME_STATS[stat]) {
          mergedStats[stat] = FIXED_GAME_STATS[stat];
        }
        return mergedStats || {};
      }, {}),
    [game?.stats],
  );

  return (
    <div
      className="column gap-10 rounded-borders py-md pl-md pr-sm"
      style={{background: '#FFFFFF14'}}>
      <div className="row justify-end">
        {Object.keys(mergedGameStats).map(key => (
          <div
            key={key}
            style={{width: 28}}
            className="row items-center justify-center">
            <img
              alt={key}
              height={12}
              width="100%"
              src={require(`../../../../Assets/Icons/Globals/${key}.svg`)}
            />
          </div>
        ))}
      </div>
      <div className="column gap-2">
        <div className="row items-center justify-between">
          <span className="font-12 whiteText bold-500">{game?.team1_name}</span>
          <div className="row items-center">
            {Object.values(mergedGameStats || {})?.map((values, index) => (
              <div
                key={index}
                style={{width: 28}}
                className="row items-center justify-center font-12 whiteText bold-500">
                <span>{values?.team1_value}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="row items-center justify-between">
          <span className="font-12 whiteText bold-500">{game?.team2_name}</span>
          <div className="row items-center">
            {Object.values(mergedGameStats || {})?.map((values, index) => (
              <div
                key={index}
                style={{width: 28}}
                className="row items-center justify-center font-12 whiteText bold-500">
                <span>{values?.team2_value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GameStats;
