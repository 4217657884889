import React, {useMemo} from 'react';

const LiveGameScoreStats = ({game}) => {
  const scoreSetsData = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(game?.stats || {})?.filter(([key]) =>
          key?.startsWith('score_set'),
        ) || {},
      ),
    [game?.stats],
  );

  const showGamePassIcon = useMemo(
    () =>
      game?.sport_alias === 'Tennis' ||
      game?.sport_alias === 'Volleyball' ||
      game?.sport_alias === 'TableTennis' ||
      game?.sport_alias === 'Cricket',
    [game?.sport_alias],
  );

  return (
    !!Object.keys(scoreSetsData || {})?.length && (
      <div
        className="pa-md rounded-borders column"
        style={{background: '#FFFFFF14'}}>
        <div
          className="row items-center justify-end gap-5 pb-sm"
          style={{borderBottom: `1px solid #FFFFFF80`}}>
          {/*<span className="whiteText font-13 ellipsis">Best of 3</span>*/}
          <div className="row items-center gap-10">
            <span style={{width: 20}} className="font-13 whiteText text-center">
              T
            </span>
            <div className="row items-center gap-5">
              {Object.keys(scoreSetsData || {})?.map((key, index) => (
                <span
                  key={key}
                  style={{width: 20}}
                  className="font-13 whiteText text-center">
                  {index + 1}
                </span>
              ))}
              {/*<span style={{width: 20}} className="font-13 whiteText text-center">*/}
              {/*  {Object.keys(scoreSetsData || {})?.length + 1}*/}
              {/*</span>*/}
            </div>
          </div>
        </div>

        <div className="row items-center justify-between gap-5">
          <span className="whiteText font-11 bold-700 pt-md ellipsis">
            {game?.team1_name}
          </span>
          <div className="row items-center gap-10">
            <div
              className="row items-center gap-5 pt-md"
              style={{borderRight: `1px solid #FFFFFF80`}}>
              {showGamePassIcon &&
                (game?.info?.pass_team === 'team1' ? (
                  <img
                    width={12}
                    src={require(
                      `../../../../Assets/Icons/SingleGameActiveTeam/${game?.sport_alias}.svg`,
                    )}
                  />
                ) : game?.sport_alias === 'Cricket' &&
                  game?.info?.pass_team !== 'team1' ? (
                  <img
                    width={12}
                    src={
                      require(
                        `../../../../Assets/Icons/SingleGameActiveTeam/CricketBall.svg`,
                      )?.default
                    }
                  />
                ) : null)}
              <span
                style={{width: 20}}
                className="bold-800 font-13 orangeText text-center">
                {game?.info?.score1}
              </span>
            </div>
            <div className="row items-center gap-5 pt-md">
              {Object.keys(scoreSetsData || {})?.map(key => (
                <span
                  key={key}
                  className="bold-800 text-center font-13"
                  style={{
                    width: 20,
                    color: '#FFFFFF80',
                    minWidth: 'fit-content',
                  }}>
                  {scoreSetsData?.[key]?.team1_value}
                </span>
              ))}
              {/*<span*/}
              {/*  className="bold-800 text-center font-13"*/}
              {/*  style={{color: '#FFFFFF80', width: 20}}>*/}
              {/*  -*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
        <div className="row items-center justify-between gap-5">
          <span className="whiteText font-11 bold-700 pt-md ellipsis">
            {game?.team2_name}
          </span>

          <div className="row items-center gap-10">
            <div
              className="row items-center gap-5 pt-md"
              style={{borderRight: `1px solid #FFFFFF80`}}>
              {showGamePassIcon &&
                (game?.info?.pass_team === 'team2' ? (
                  <img
                    width={12}
                    src={require(
                      `../../../../Assets/Icons/SingleGameActiveTeam/${game?.sport_alias}.svg`,
                    )}
                  />
                ) : game?.sport_alias === 'Cricket' &&
                  game?.info?.pass_team !== 'team2' ? (
                  <img
                    width={12}
                    src={
                      require(
                        `../../../../Assets/Icons/SingleGameActiveTeam/CricketBall.svg`,
                      )?.default
                    }
                  />
                ) : null)}
              <span
                style={{width: 20}}
                className="bold-800 font-13 orangeText text-center">
                {game?.info?.score2}
              </span>
            </div>
            <div className="row items-center gap-5 pt-md">
              {Object.keys(scoreSetsData || {})?.map(key => (
                <span
                  key={key}
                  className="bold-800 text-center font-13"
                  style={{
                    width: 20,
                    color: '#FFFFFF80',
                    minWidth: 'fit-content',
                  }}>
                  {scoreSetsData?.[key]?.team2_value}
                </span>
              ))}
              {/*<span*/}
              {/*  className="bold-800 text-center font-13"*/}
              {/*  style={{color: '#FFFFFF80', width: 20}}>*/}
              {/*  -*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default LiveGameScoreStats;
