import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import useCasino from '../../../Hooks/UseCasino';
import {useScreenSize, useStorage} from '../../../Hooks';

import {
  setAuthModal,
  setShowBetSlip,
  setShowMobileBetSlip,
} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import {
  getIsLoggedIn,
  getShowBetSlip,
  getShowMobileBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';

import {AppBetSlip, AppButton} from '../../../Components/UI';
import HomeProviders from '../../../Components/Home/HomeProviders';
import MobileHeader from '../../../Components/Header/MobileHeader';
import GamesListContainer from '../../../Components/Casino/Games/GamesListContainer';
import LobbyGamesContainer from '../../../Components/Casino/Lobby/LobbyGamesContainer';
import GameActionBarHeader from '../../../Components/Casino/GameActionBar/GameActionBarHeader';
import GameActionBarFooter from '../../../Components/Casino/GameActionBar/GameActionBarFooter';

import {
  SKILL_NAME,
  SLOTS_NAME,
  LIVE_CASINO_NAME,
  CATEGORY_LIVE_CASINO_ID,
  CATEGORY_SKILL_GAMES_ID,
  CATEGORY_SLOTS_GAMES_ID,
  PROVIDERS_ALL_GAMES_NAME,
  SUB_CATEGORIES_LAST_PLAYED,
  CATEGORY_BOOSTED_RTP_GAMES_ID,
} from '../../../Constants/CasinoMenuFilter';
import {FUN, REAL, SITE_ID} from '../../../Constants/Socket';
import {
  BREAKPOINT_XS,
  APP_MODAL_CLOSE_TIMEOUT_MS,
} from '../../../Constants/Globals';

import coloredClose from '../../../Assets/Icons/Globals/coloredClose.svg';
import {ReactComponent as CasinoGameBack} from '../../../Assets/Icons/Casino/CasinoGameBack.svg';
import {ReactComponent as FullScreenArrowDown} from '../../../Assets/Icons/Casino/FullScreenArrowDown.svg';
import {ReactComponent as ShowBetslipArrow} from '../../../Assets/Icons/Casino/showBetslipArrow.svg';
import {ReactComponent as FullScreenArrowTop} from '../../../Assets/Icons/Casino/FullScreenArrowTop.svg';

import './index.scss';

const Casino = () => {
  const params = useParams();
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {getCasinoGames} = useCasino();
  const {getLastPlayedGamesFromStorage} = useStorage();

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);

  const offset = useRef(0);
  const observerRef = useRef(null);
  const inputValueRef = useRef(getParam.get('search'));

  const [games, setGames] = useState([]);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [totalCounts, setTotalCounts] = useState(0);
  const [isGameLoading, setIsGameLoading] = useState(false);
  const [lastPlayedGames, setLastPlayedGames] = useState([]);
  const [isGamesLoading, setIsGamesLoading] = useState(false);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(width < BREAKPOINT_XS);

  const modeParam = useMemo(() => getParam.get('mode'), [getParam]);
  const searchValue = useMemo(() => getParam.get('search'), [getParam]);
  const providerParam = useMemo(() => getParam.get('provider'), [getParam]);
  const gameIdParam = useMemo(() => getParam.get('casinoGameId'), [getParam]);

  const currentGame = useMemo(
    () => games?.find(item => +item?.extearnal_game_id === +gameIdParam),
    [gameIdParam, games],
  );

  const subCategoryIdParam = useMemo(
    () => getParam.get('subCategoryId'),
    [getParam],
  );

  const isLobbyPage = useMemo(
    () => location?.pathname?.includes('lobby'),
    [location?.pathname],
  );

  const src = useMemo(
    () =>
      (
        'https://games.pigabet.co.tz/LaunchGame' +
        `?partnerId=${SITE_ID}` +
        '&language=en' +
        `&devicetypeid=${width < BREAKPOINT_XS ? 2 : 1}` +
        `&gameId=${gameIdParam}` +
        `&openType=${modeParam}` +
        `&token=${user?.auth_token}`
      )?.trim(),
    [gameIdParam, modeParam, user?.auth_token, width],
  );

  const LAST_PLAYED_DATA = useMemo(() => {
    // const storageData = getLastPlayedGamesFromStorage()
    //   ?.filter(item =>
    //     params?.category === 'slots'
    //       ? item?.categoryId === CATEGORY_SLOTS_GAMES_ID
    //       : params?.category === 'liveCasino'
    //       ? item?.categoryId === CATEGORY_LIVE_CASINO_ID
    //       : params?.category === 'skill'
    //       ? item?.categoryId === CATEGORY_SKILL_GAMES_ID
    //       : params?.category === 'boostedRTP'
    //       ? item?.categoryId === CATEGORY_BOOSTED_RTP_GAMES_ID
    //       : item,
    //   )
    //   ?.map(item => `${item?.gameId}`);
    //
    // return lastPlayedGames?.filter(item =>
    //   storageData?.includes(item?.extearnal_game_id),
    // );
    return lastPlayedGames;
  }, [lastPlayedGames]);

  const onShowMore = useCallback(() => {
    if (moreItemsLoading || !games?.length) {
      return;
    }
    setMoreItemsLoading(true);
    offset.current = offset.current + 30;
    getCasinoGames({
      extra_category_id:
        params?.category === LIVE_CASINO_NAME ? subCategoryIdParam : '',
      provider: providerParam === PROVIDERS_ALL_GAMES_NAME ? '' : providerParam,
      category:
        params?.category === LIVE_CASINO_NAME
          ? CATEGORY_LIVE_CASINO_ID
          : params?.category === SKILL_NAME
          ? CATEGORY_SKILL_GAMES_ID
          : subCategoryIdParam,
      offset: offset.current,
      limit: offset.current + 30,
    })
      ?.then(res => {
        setGames(prevState => [...prevState, ...res?.data?.games]);
      })
      ?.finally(() => setMoreItemsLoading(false));
  }, [
    params,
    games?.length,
    providerParam,
    getCasinoGames,
    moreItemsLoading,
    subCategoryIdParam,
  ]);

  const handleObserver = useCallback(
    entries => {
      const target = entries[0];
      const currentScrollY = window.scrollY;

      if (target.isIntersecting && currentScrollY > lastScrollY) {
        onShowMore();
      }
      setLastScrollY(currentScrollY);
    },
    [lastScrollY, onShowMore],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {threshold: 0.5});
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [observerRef.current, lastScrollY, params?.category, subCategoryIdParam]);

  useEffect(() => {
    offset.current = 0;
    setIsGamesLoading(true);

    if (subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED) {
      setLastPlayedGames([]);

      const externalIdsString = getLastPlayedGamesFromStorage()
        ?.filter(item =>
          providerParam && providerParam !== PROVIDERS_ALL_GAMES_NAME
            ? providerParam?.split?.(',')?.includes(item?.providerName)
            : item,
        )
        ?.filter(item =>
          params?.category === SLOTS_NAME
            ? item?.categoryId !== CATEGORY_LIVE_CASINO_ID &&
              item?.categoryId !== CATEGORY_SKILL_GAMES_ID &&
              item?.categoryId !== CATEGORY_BOOSTED_RTP_GAMES_ID
            : params?.category === LIVE_CASINO_NAME
            ? item?.categoryId === CATEGORY_LIVE_CASINO_ID
            : params?.category === SKILL_NAME
            ? item?.categoryId === CATEGORY_SKILL_GAMES_ID
            : // : params?.category === 'boostedRTP'
              // ? item?.categoryId === CATEGORY_BOOSTED_RTP_GAMES_ID
              false,
        )
        ?.sort((a, b) => b?.playedCount - a?.playedCount)
        ?.map(game => game?.gameId);

      if (externalIdsString?.length > 0) {
        getCasinoGames({
          limit: 30,
          externalIds: externalIdsString?.join(','),
          provider:
            providerParam === PROVIDERS_ALL_GAMES_NAME ? '' : providerParam,
        })
          ?.then?.(response => {
            const specificGames = response?.data?.games;

            const externalIdsString_str = externalIdsString.map(String);

            specificGames.sort(
              (a, b) =>
                externalIdsString_str.indexOf(a.extearnal_game_id) -
                externalIdsString_str.indexOf(b.extearnal_game_id),
            );

            setLastPlayedGames(specificGames);
          })
          ?.finally(() => {
            setTimeout(() => {
              setIsGamesLoading(false);
            }, 300);
          });
      } else {
        setTimeout(() => {
          setIsGamesLoading(false);
        }, 300);
      }
    } else {
      getCasinoGames({
        extra_category_id:
          params?.category === LIVE_CASINO_NAME ? subCategoryIdParam : '',
        provider:
          providerParam === PROVIDERS_ALL_GAMES_NAME ? '' : providerParam,
        category:
          params?.category === LIVE_CASINO_NAME
            ? CATEGORY_LIVE_CASINO_ID
            : params?.category === SKILL_NAME
            ? CATEGORY_SKILL_GAMES_ID
            : subCategoryIdParam,
        offset: 0,
        limit: 30,
      })
        ?.then(res => {
          setTotalCounts(+res?.data?.total_count);
          setGames(res?.data?.games);
        })
        ?.finally(() => {
          setTimeout(() => {
            setIsGamesLoading(false);
          }, 300);
        });
    }
  }, [providerParam, subCategoryIdParam, params?.category, gameIdParam]);

  useEffect(() => {
    if (
      !(
        gameIdParam &&
        (modeParam === FUN || (modeParam === REAL && isLoggedIn))
      ) &&
      width < BREAKPOINT_XS
    ) {
      if (!!document.querySelector('.appBetSlipWrapper_mobile')) {
        document.querySelector('.appBetSlipWrapper_mobile').style.transition =
          'none';
      }
    }
  }, [gameIdParam, isLoggedIn, modeParam, width]);

  useEffect(
    () => () => {
      if (
        document.querySelector('.appBetSlipWrapper_mobile') &&
        width < BREAKPOINT_XS
      ) {
        document.querySelector('.appBetSlipWrapper_mobile').style.transition =
          '';
      }
    },
    [width],
  );

  useEffect(() => () => setIsMenuOpen(true), [gameIdParam]);

  return (
    <div className="casinoPageContainer">
      {gameIdParam &&
        (modeParam === FUN || (modeParam === REAL && isLoggedIn)) && (
          <div
            className="column blueBackground"
            style={{
              width,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: 'fixed',
              ...(currentGame?.background
                ? {background: `url(${currentGame?.background})`}
                : {}),
              zIndex:
                width > BREAKPOINT_XS
                  ? 2147483640 /* +1 more regarding livechat widget*/
                  : 33,
              height:
                width < BREAKPOINT_XS && isMenuOpen
                  ? 'calc(100% - 46px)'
                  : '100%',
            }}>
            <div className="full-width rowCenter">
              {!isMenuOpen && (
                <div
                  className="cursor-pointer headerToggleButton_open"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsMenuOpen(true);
                  }}>
                  <FullScreenArrowDown width={35} />
                </div>
              )}
              {isMenuOpen && (
                <div className="full-width flex column items-center casinoGameBackContainer">
                  <MobileHeader
                    isCasinoGame
                    showMenu={false}
                    showFilters={false}
                    showBetSlipToggleButton
                    showLanguageChooser={false}
                    leftIcon={
                      <div
                        className="ml-md cursor-pointer rowCenter full-height"
                        onClick={() => {
                          dispatch(setShowMobileBetSlip(false));
                          getParam.delete('mode');
                          getParam.delete('casinoGameId');
                          setGetParam(getParam);
                        }}>
                        <CasinoGameBack />
                      </div>
                    }
                  />
                  <div
                    className="cursor-pointer headerToggleButton"
                    onClick={() => setIsMenuOpen(false)}>
                    <FullScreenArrowTop width={35} />
                  </div>
                </div>
              )}
              {!showMobileBetSlip && width < BREAKPOINT_XS && (
                <div
                  className="cursor-pointer betslipToggleButton"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(setShowMobileBetSlip(true));
                  }}>
                  <ShowBetslipArrow style={{transform: 'scale(1.5)'}} />
                </div>
              )}
            </div>

            <div className="row fit">
              <iframe
                src={src}
                width="100%"
                height="100%"
                scrolling="no"
                title="casino_game"
                onLoad={() => {
                  if (
                    width < BREAKPOINT_XS &&
                    !!document.querySelector('.appBetSlipWrapper_mobile')
                  ) {
                    document.querySelector(
                      '.appBetSlipWrapper_mobile',
                    ).style.transition = '';
                  }
                  setTimeout(() => {
                    setIsGameLoading(false);
                  }, 500);
                }}
              />
              {width > BREAKPOINT_XS && (
                <div
                  // style={{marginTop: isMenuOpen ? 40 : 0}}
                  className={`appBetSlipWrapper_desktop flex-shrink blueBackground rounded-top--borders ${
                    width >= BREAKPOINT_XS
                      ? !!showBetSlip
                        ? 'column'
                        : 'hide'
                      : ''
                  } appBetSlipWrapper`}>
                  <div
                    className="row items-center  justify-between yellowBackground pa-sm"
                    style={{
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}>
                    <div
                      style={{
                        width: 26,
                        height: 26,
                      }}
                    />
                    <span className="font-15 blueText bold-600">
                      {t('myBets')}
                    </span>
                    <div
                      style={{
                        width: 26,
                        height: 26,
                      }}
                      onClick={() => dispatch(setShowBetSlip(false))}
                      className="whiteBackground items-center justify-center flex rounded-borders cursor-pointer z-index-1">
                      <img
                        alt="close"
                        width={10}
                        height={10}
                        src={coloredClose}
                      />
                    </div>
                  </div>
                  <div
                    className={`full-height betSlipDesktopWrapper rounded-borders`}>
                    <AppBetSlip />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      {/*{isGameLoading &&*/}
      {/*  gameIdParam &&*/}
      {/*  (modeParam === FUN || (modeParam === REAL && isLoggedIn)) && (*/}
      {/*    <div*/}
      {/*      style={{*/}
      {/*        inset: 0,*/}
      {/*        zIndex: 200,*/}
      {/*        position: 'fixed',*/}
      {/*        background: 'var(--appBlue)',*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*{isGameVisible && (*/}
      {/*  <>*/}
      {/*    <GameActionBarFooter*/}
      {/*      setIsFullScreen={setIsFullScreen}*/}
      {/*      gameTitle={*/}
      {/*        games?.find(item => +item?.extearnal_game_id === +gameId)?.name ??*/}
      {/*        LAST_PLAYED_DATA?.find(*/}
      {/*          item => +item?.extearnal_game_id === +gameId,*/}
      {/*        )?.name ??*/}
      {/*        ''*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <div*/}
      {/*      className={`${*/}
      {/*        isFullScreen*/}
      {/*          ? 'fullScreenContainer overflow-hidden darkBlueBackground'*/}
      {/*          : `mt-md gameIframeContainer`*/}
      {/*      }`}>*/}
      {/*      {isFullScreen && (*/}
      {/*        <GameActionBarHeader setIsFullScreen={setIsFullScreen} />*/}
      {/*      )}*/}
      {/*      <div*/}
      {/*        className={`full-width ${*/}
      {/*          isFullScreen ? 'casinoGameIframe' : 'full-height'*/}
      {/*        }`}>*/}
      {/*        <iframe*/}
      {/*          src={src}*/}
      {/*          scrolling="no"*/}
      {/*          className="fit"*/}
      {/*          title="casino_game"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
      {/*{!isLobbyPage && <HomeProviders />}*/}
      {/*{!isGameVisible && !isLobbyPage && <HomeProviders />}*/}
      {/*{!isGameVisible && width > BREAKPOINT_XS && (*/}
      {/*  <CasinoActionBar inputValueRef={inputValueRef} showLastPlayedButton />*/}
      {/*)}*/}
      {isLobbyPage ? (
        <LobbyGamesContainer setIsGameLoading={setIsGameLoading} />
      ) : (
        <GamesListContainer
          isGamesLoading={isGamesLoading}
          moreItemsLoading={moreItemsLoading}
          setIsGameLoading={setIsGameLoading}
          data={
            subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED
              ? LAST_PLAYED_DATA
              : games
          }
        />
      )}

      {games?.length < totalCounts &&
        !isLobbyPage &&
        subCategoryIdParam !== SUB_CATEGORIES_LAST_PLAYED && (
          // <div className="rowCenter my-xl">
          //   <AppButton
          //     type={3}
          //     width={150}
          //     onClick={onShowMore}
          //     title={<span className="boldText">{t('showMore')}</span>}
          //   />
          // </div>
          <div ref={observerRef} style={{height: '1px', width: '100%'}} />
        )}
    </div>
  );
};

export default Casino;
