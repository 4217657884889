import React, {memo, useEffect, useMemo} from 'react';
import './index.scss';
import {useDispatch, useSelector} from 'react-redux';

import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

import {
  FUN,
  REAL,
  TOKEN,
  OPEN_TYPE,
  SPORTS_JACKPOT_URL,
} from '../../../Constants/Socket';
import {setShowBetSlip} from '../../../Redux/AppSlice';

const SportsJackpot = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const src = useMemo(
    () =>
      SPORTS_JACKPOT_URL?.replace(OPEN_TYPE, isLoggedIn ? REAL : FUN)?.replace(
        TOKEN,
        isLoggedIn ? user?.auth_token : null,
      ),
    [isLoggedIn, user?.auth_token],
  );

  useEffect(() => {
    dispatch(setShowBetSlip(false));
  }, []);

  return (
    <iframe src={src} className="sportsJackpotIframe" title="sportsJackpot" />
  );
};

export default memo(SportsJackpot);
